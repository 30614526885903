.footer--networks {
  background: #000000;
  color: #ffffff;
  padding: 10px;
  text-align: center;
}

.footer--networks .footer--networks__social {
  display: inline-block;
}

.footer--networks a {
  padding-right: 20px;
  transition: opacity .3s;
}

.footer--networks a:hover {
  opacity: .8;
}

.footer-menus {
  background: url(../../img/footer/footer-bg.png);
  min-height: 215px;

  @media (max-width: 767px) {
    background: url(../../img/FOND.png);
  }
}

.footer-menus .row {
  padding-top: 30px;
}

.footer-menus a {
  font-family: $font-raleway;
  font-size: 1.2rem;
  font-weight: 500;
  text-decoration: none;
  padding-bottom: 15px;
  display: inline-block;
}

.footer-logos a {
  font-weight: bold;
  display: block;
  padding-bottom: 20px;
}


.footer-menus ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.form-popup {
  display: none;
  position: absolute;
  z-index: 11;
  background-color: white;
}

.newsletter_pop_up {
  width: 275px;
  min-height: 255px;
  left: 30%;
  top: -85px;
}

#newsletter {
  padding-left: 38px;
  padding-right: 38px;
}

#newsletter input[type=email] {
  height: 40px;
  margin-bottom: 13px;
  background: #e6e6e6;
}

#newsletter input[type=email]:focus + #label_email, #newsletter input[type=email]:valid + #label_email, #newsletter input[type=email]:active + #label_email {
  top: 32px !important;
  padding-left: 10px !important;
  margin-top: 2px;
  font-size: 12px;
  cursor: initial;
}

#newsletter input[type=email]:focus, #newsletter input[type=email]:valid {
  font-size: 14px;
}

#label_email {
  position: absolute;
  top: 47px;
  color: black;
  font-style: italic;
  font-size: 14px;
  margin-bottom: 0px;
  padding-left: 14px;
}

#newsletter label {
  color: black;
  font-style: italic;
  font-size: 14px;
  margin-bottom: 25px;
  font-family: $font-raleway;
  padding-right: 30px;
}

.row_newsletter_pop_up {
  display: flex;
  flex-direction: row;
}

#newsletter input[type=checkbox] {
  margin: 0px;
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
  box-sizing: border-box;
  padding: 0;
  width: 20px;
  height: 20px;
  background: #fff;
  border: 1px solid #ccc;
  vertical-align: middle;
  position: absolute;
  right: 34px;
  box-shadow: 0px 3px 5px #555;
}

input[type="checkbox"]:checked {


  &:before {
    content: "\2713";
    display: block;
    color: grey;
    font-size: 18px;
    position: absolute;
    padding-left: 2px;
  }
}

.croix {
  background: url("../../img/croix.png");
  height: 16px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 235px;
}

#newsletter .lien-filets {
  position: absolute;
  bottom: 10px;
  left: 50%;
  margin: 0px;
  transform: translateX(-50%);
}

footer a {
  transition: all .3s;
}

footer li a:hover {
  text-decoration: underline;
}

.footer-logos a:hover {
  opacity: .8;
}
