#page404{
    text-align:center;
    // padding:50px 0;
   // height:500px;
    line-height:500px;

    #page404_infos{
        line-height:normal;
        vertical-align:middle;
        display:inline-block;
        padding-bottom:15px;
        h1::first-letter{
            font-size:8rem!important;
        }
        h1{
            font-feature-settings: "kern" on,"liga" on,"rlig" on,"calt" on,"ss01" on;
            font-size:3em;
            font-family: $font-lustslim;
            color:#ffffff;
            margin:0;
        }

        #error404_texte{
            padding:30px 0 20px 0;
            font-weight: 500;
            p{
                color:#ffffff;
                font-size:1.6em;
            }
        }


        a{
            &.lien-filets_black{
                background-color:#ffffff;
                background-position:6px center;
                padding:12px 12px 12px 50px;
            }
        }
    }

    @include media-breakpoint-down(xs) {
        //height:250px;
        line-height:250px;

        #page404_infos{
            #error404_texte{
                p{
                    font-size:1.4em;
                }
            }
        }


    }
}

