.paragraphe_newsletter{
  .paragraphe{
    padding-left: 0px;
    padding-right: 0px;
    margin-top: 25px;
    margin-bottom: 25px;

    h2{
      text-transform: uppercase;
      font-family: $font-bebasneue;
      font-size: px2rem(28);
    }
  }
}

.bandeau_titre{
  background-image: url("../../img/bandeau_titre.png");
  padding-top: 12px;
  padding-bottom: 12px;
}

.titre_page_newsletter{
  color: white;
  font-size: px2rem(42);
  font-family: $font-lustslim;
}

#input_choix_newsletter{
  display: none;
}

.div_checkbox{
  fieldset{
    border: none;
    padding-top: 0px;
    legend{
      border: none;
      font-family: Raleway,Arial,Verdana,sans-serif;
      display: block;

      color: #000;
      font-size: 1em;
      font-weight: 700;
    }
    label{
      margin-left: 0px !important;
    }
    input[type=checkbox]{
      -webkit-appearance: none;
      -moz-appearance: none;
      box-sizing: border-box;
      padding: 0;
      width: 20px;
      height: 20px;
      background: #fff;
      border: 1px solid #ccc;
      vertical-align: middle;
      box-shadow: 0 3px 5px #555;
    }
    p{
      font-size: px2rem(14) !important;
      font-weight: 500;
    }
  }
}

#abo_newsletter{

  input{
    color: #8e8e8e;
  }

  input[type=submit]{
    color: black;
  }
}

.newsletter{
  margin-bottom: 100px;
}

