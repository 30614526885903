.header-compte {
  text-align: center;

  #bloc_header,
  #bloc_header_login,
  .menu-link {
    height: 100%;
    vertical-align: middle;
  }

  #bloc_header_login {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .menu-link {
    align-items: center;
    align-self: center;
    display: inline-flex;
    transition: all .5s;
    background-position-y: 100%;
    background-repeat: no-repeat;
    background-size: 100% 0px;

    &:hover {
      text-decoration: none;
      background-size: 100% 5px;
      background-image: linear-gradient(to right, #50ae9b, #bbbfab);
    }
  }


}

.header-compte-logged {}

.header-abo {
  text-align: right;
  align-self: center;

  .btn-gradient {
    padding: 14px 0;
    position: relative;
    top: -10px;
  }

  .btn-gradient_actif {
    color: #f68670;
    background: #FFFFFF;
    border: solid 2px #f68670;

    &:hover {
      color: #000;
    }
  }

  @media (max-width: 767px) {
    width: auto;

    .btn-gradient {
      padding: 14px 40px;
    }

    .btn-gradient_change {
      padding-left: 14px;
      padding-right: 14px;
    }
  }
}

.header-abo-change {
  @media (max-width: 380px) {
    display: none;
  }
}

.header-buttons {
  @media (max-width: 767px) {
    position: absolute;
    text-align: center;
    align-self: center;
    margin-left: 100px;
    z-index: 1033;
    top: 25px;
    width: auto;
  }
}

#div_menu_haut-gauche {
  height: 100%;
  z-index: 1032;
}

#div_menu_haut-gauche a {
  @extend .menu-link;
}

#div_menu_haut-gauche ul {
  list-style-type: none;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;

  height: 100%;

  li {
    height: 100%;
    padding: 0;
    margin: 0;

    a {
      height: 100%;
      display: inline-flex;
      align-items: center;
      transition: all .5s;
      background-position-y: 100%;
      background-repeat: no-repeat;
      background-size: 100% 0px;
      transition: all .5s;
      position: relative;
      z-index: 1031;


      &:hover {
        background-size: 100% 5px;
        background-image: linear-gradient(to right, #005576, #bbbfab);
      }

    }

    &#menu_haut-gauche_2 a:hover {
      background-size: 100% 5px;
      background-image: linear-gradient(to right, #d42323, #ff5d5d);
    }

    &#menu_haut-gauche_1.selected a {
      background-size: 100% 5px;
      background-image: linear-gradient(to right, #005576, #bbbfab);
    }

    &#menu_haut-gauche_2.selected a {
      background-size: 100% 5px;
      background-image: linear-gradient(to right, #d42323, #ff5d5d);
    }

  }
}

.header-liens {

  /*height: 100%;*/
  /*align-self: center;*/
  @media (max-width: 767px) {
    display: none;
  }
}

.menu-link {
  font-family: $font-bebasneue;
  font-size: 2.1rem;
  background: none;
  padding: 10px;
  display: inline-block;
  text-decoration: none;
}


header {
  a {
    position: relative;
    z-index: 1032;
  }

  .row {
    min-height: 90px;
  }
}

.header-logo {
  align-self: center;
  text-align: center;

  @media (max-width: 767px) {
    position: absolute;
    text-align: left;
    width: 105px;
    z-index: 1033;
  }
}

@media (max-width: 767px) {
  .menu_burger {
    z-index: 1034;
  }
}

header {
  padding: 0;
  background-color: #ffffff;
  box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.3);
  min-height: 90px;
  transition: all 0.3s ease-in-out;
  position: relative;
}

#header-search-btn {
  position: relative;
  z-index: 1032;

  @media (max-width: 767px) {
    display: none;
  }
}

.header-buttons {
  @media (max-width: 767px) {
      #header-search-btn {
        display: none !important;
      }
  }
}

@media (max-width: 995px) {
  .menu-compte {
    text-align: right;

    .menu-compte--deco {
      display: none;
    }

    .menu-compte--films {
      top: 10px;
      position: relative;
    }
  }

  .header-abo .btn-gradient_change {
    display: none;
  }

  .header-compte {
    position: relative;
    z-index: 1032;

    #bloc_header_login {
      display: -webkit-box;
      display: flex;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: flex-end;
    }
  }

  .menu-compte--nom {
    background: none;

    &:before {
      content: url(../../img/perso.png);
      margin-right: 10px;
    }
  }
}


.search-overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 1031;
  display: block;
}


.btn_rechercher {
  position: absolute;
  z-index: 1035;
}

#header-menu-btn {
  z-index: 1032;

  @media (max-width: 320px){
    padding-left: 0;
    margin-left: -6px;
  }
}

#header-search-btn{
  @media (max-width: 860px){
    display: none;
  }
}