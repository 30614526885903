.promo {
  font-family: $font-raleway;
  font-size: px2rem(28);
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 130px;
  padding-right: 130px;
  color: white;
  text-align: center;
  line-height: 2;
  @media (max-width: 1334px) {
    padding-left: 10px;
    padding-right: 10px;
  }

}

.bandeau_promotion {
  background: #f8a692;
  max-width: initial;
  min-height: 55px;
  color: #ffffff;

  &.important {
/*
    background: red;
    opacity: .8;*/
  }

  @media (max-width: 546px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  .promo {
    margin-bottom: 0px;
    font-family: $font-raleway;
    font-size: px2rem(20);
    position: relative;
    line-height: 1.5em;
  }

}


.promo a {
  text-decoration: none;
  background-position: center right;
  padding-left: 7px;
  padding-right: 43px;
  font-size: px2rem(16);

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}

.promo a:hover {
  color: white;
  text-decoration: none;
}

.croix_promo {
  position: absolute;
  background: url("../../img/croix_promo.png");
  height: 16px;
  margin-top: 10px;
  right: 0px;
}

.en_ce_moment {
  background: url("../../img/bandeau_revue.png");
  padding-bottom: 50px;

  .titre_en_ce_moment {
    margin-left: 10px;
    margin-right: 10px;
    font-family: $font-bebasneue;
    font-size: px2rem(38);
    color: #005576;
    padding-top: 10px;
  }

  .texte_en_ce_moment {
    margin-left: 10px;
    margin-right: 10px;
    font-size: 1.8rem;
  }

  .bloc_en_ce_moment {
    margin-top: 20px;
    min-height: 228px;
    padding-top: 56.25%;
    position: relative;

    .date_offline {
      margin-left: 10px;
      background-image: url("../../img/date.png");
      background-repeat: no-repeat;
      background-position-y: center;
      padding-top: 20px;
      padding-bottom: 20px;
      color: white;
      font-size: px2rem(15);
      font-family: $font-raleway;
      font-weight: bold;
      width: 40px;
      margin-bottom: 20px;
      text-align: center;
      position: absolute;
      top: 0px;
      left: 0px;
    }

    .texte_bloc_en_ce_moment {
      position: absolute;
      bottom: 0px;

      .titre_bloc_en_ce_moment {
        font-family: $font-lustslim;
        font-size: px2rem(30);
        color: white;
        margin-right: 18px;
        margin-left: 18px;
      }

      .sous_titre_bloc_en_ce_moment {
        font-family: $font-raleway;
        font-weight: bold;
        font-size: px2rem(20);
        color: white;
        margin-right: 18px;
        margin-left: 18px;
        margin-bottom: 20px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-lamp: 3;
        -webkit-box-orient: vertical;

      }
    }

  }
}

.bloc_en_ce_moment a, bloc_en_ce_moment a:hover {
  text-decoration: none;
  color: white;
}

.bloc_en_ce_moment a:before {
  content: '';
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
}
