.titre_autour_sorties {
  background: none !important;

  font-family: $font-lustslim;
  font-feature-settings: "kern" on, "liga" on, "rlig" on, "calt" on, "ss01" on;

  #h1 {
    font-size: 54px;
    color: black !important;

    &::first-letter {
      font-size: 80px;
    }
  }

  &::first-letter {
    font-size: 80px;
  }
}

.bloc_cineaste {
  height: 191px !important;
  width: 191px;
  border-radius: 50%;
  box-shadow: none !important;
  margin-bottom: 65px;
  margin-left: 7px;
  margin-top: 7px;

  .text_cineaste {
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    text-align: center;
    color: white;
    font-family: $font-bebasneue;
    margin-top: 0px !important;
    height: initial !important;
    width: initial !important;

    .titre_cineaste {
      font-size: px2rem(30) !important;
    }
  }
}

.bloc_evenement {
  min-height: 228px;
  min-width: 100%;
  height: initial !important;
  max-width: initial !important;
  padding-top: 56.25% !important;
  background-position: center;
  background-size: cover;
  box-shadow: none;

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background: linear-gradient(transparent 60%, #000000);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  .titre_evenement {
    font-size: px2rem(28);
  }

  .text_evenement {
    position: absolute;
    bottom: 0px;
    z-index: 2;

    .event {
      color: white;
      font-family: $font-bebasneue;
      text-transform: uppercase;
      font-size: px2rem(28);
      margin-top: 15px;
    }
  }
}

.bloc_sortie, .bloc_cineaste, .bloc_evenement {
  position: relative;
  height: 100%;
  transition: all .3s;
  box-shadow: 1px 1px 12px #555;
  max-width: 194px;
  display: flex;
  padding: 12px;

  transition: all .3s;

  .col {
    position: static;
  }

  &:hover {
    text-decoration: none;
    filter: brightness(110%);
  }

  a {
    text-decoration: none;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }

  .text_sortie, .text_cineaste {
    margin-top: 256px;
    display: flex;
    flex-direction: column;
    height: calc(100% - 256px);
    width: 194px;

    .titre_sortie, .titre_cineaste {
      text-transform: uppercase;
      font-size: px2rem(28);
      margin-top: 15px;
    }

    .sous_titre_sortie {
      font-size: px2rem(16);
      font-weight: bold;
    }
  }

}

.sortie_selected::after, .evenement_selected::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-image-source: linear-gradient(to bottom right, #005576, #bcbfaa);
  border-image-slice: 1;
  border-width: 6px;
  border-style: solid;
}

.contour {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../img/round_border.png");
  background-repeat: no-repeat;
}

.fond {
  background: url(../../img/fond_presentation.png);
  max-width: unset;
  margin-bottom: 25px;
  
}
#anchore-slider {
  height: 90px; 
  margin-top: -90px; 
  visibility: hidden;
  display: block;

}

.fond_p {
  background-color: white;
  margin-top: 40px;
  margin-bottom: 40px;
  padding: 32px;

  @media(max-width: 575px){
    -webkit-box-flex: 0;
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
  }
}

.titre_presentation {
  font-size: px2rem(30);
  font-family: $font-bebasneue;
}

.text_presentation {
  padding-top: 10px;
  font-size: px2rem(16);
  font-family: $font-raleway;

  a{
    color: #fa8573;
    text-decoration: none;
  }
}

.bloc_film {
  position: relative;
  margin-bottom: 25px;
  display: flex;
  flex-grow: 1;
  align-items: flex-start;
  flex-direction: row-reverse;

  &:nth-child(2n){
    flex-direction: row;

    @media (max-width: 991px) {
      flex-direction: column;
    }
  }

  .ombre {
    box-shadow: 1px 1px 12px #555;
    flex-grow: 1;
    align-self: stretch;
    position: relative;
    transition:all .3s;
  }

  &:hover .ombre {
    background: #eeeeee;
  }

  @media (max-width: 991px) {
    flex-direction: column;
  }

  img {
    height: auto;

    @media (max-width: 991px) {
      width: 100%;
    }
  }

  .header_film {

    h3 {
      font-family: $font-lustslim;
      font-size: px2rem(32);
      color: #005576;
      margin-bottom: 0;
    }
    padding-left: 16px;
    padding-top: 16px;

    @media (max-width: 991px) {
      padding-top: 10px;
      padding-left: 20px;
    }

    .date_offline {
      display: inline-block;
      color: white;
      font-size: px2rem(15);
      font-family: $font-raleway;
      font-weight: bold;
      text-align: center;
      min-width: 32px;
      min-height: 32px;
      padding: 5px;
      line-height: 32px;
      background: #000;
      border-radius: 50%;
      margin-bottom: 20px;
    }

    .directeur_film {
      font-family: $font-raleway;
      font-size: px2rem(18);
      font-weight: bold;
      color: black;
    }
  }

  .duree_film {
    font-family: $font-raleway;
    font-size: px2rem(16);
    padding-top: 25px;
    padding-left: 20px;

    @media (max-width: 991px) {
      padding-left: 20px;
      padding-bottom: 20px;
    }
  }

  .lien-filets {
    position: absolute;
    bottom: 10px;
    right: 20px;
    font-size: px2rem(16);

    &:nth-child(2n){
      right: 20px;

      @media (max-width: 991px) {
        right: 20px;
      }
    }

    @media (max-width: 991px) {
      right: 20px;
    }
  }
}

.bloc_film:last-child {
  margin-bottom: 48px;
}

.pagination_visible {
  display: inherit !important;
}


.bloc_film{
  text-decoration: none;

  &:hover{
    text-decoration: none;
  }

  .bloc_img{
    position: relative;

    @media (max-width: 991px){
      width: 100%;
    }

    .player{
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -20px;
      margin-left: -14px;
     // transition: all .3s;


      visibility: hidden;
      @media (max-width: 991px){
        width: unset;
      }
    }
    .film_pictos{
      position: absolute;
      bottom: 20px;
      right: 20px;
      display: flex;
    }

  }

}

.autour_sorties_slider_page .bloc_cineaste{
  @media (max-width: 576px){
    margin-bottom: 20px;
  }
}

.autour_sorties_slider_page .contour{
  @media (max-width: 768px){
    background-position-x: center;
  }

}
