.actu-header img {
  width: 100%;
}

.actu-header_link {
  padding-top: 20px;
  padding-bottom: 15px;
}

.actu-content h1 {
  font-size: px2rem(28);
  font-weight: bold;
  font-family: $font-raleway;
}

.actu-tools {
  padding-top: 30px;
  padding-bottom: 10px;
}

.actu-label {
  font-size: px2rem(13);
  display: inline-block;
  background: #000000;;
  padding: 5px;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: bold;
}

.actu-date {
  font-size: px2rem(14);
  font-weight: bold;
  color: #c22323;
  padding-left: 10px;
}

.actu-resume p {
  padding-top: 20px;
  font-size: px2rem(18);
  font-weight: bold;
  border-bottom: 2px dotted #000;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.actu-content {
  margin-bottom: 50px;

  .actu-text{
    p{
      margin-bottom: 22px;
    }
  }

  p a{
    color: #fa8573;
    text-decoration: none;
  }
}

.actu-partage {
  text-align: right;
}

.actu-content p img {
  max-width: calc(100% - 20px);
  height: auto !important;
}

.actu-content iframe {
  max-width: 100%;
}

.slider_actualites, .bandeau_actu {
  max-width: initial;
}

.carousel-caption {

  @media (max-width: 780px) {
    padding-top: 25%;
  }
  @media (max-width: 430px) {
    padding-top: 0px;
    padding-bottom: 0px;
    bottom: 0px;
  }
}

.bloc_actu_slider {
  background-color: rgba(255, 255, 255, 0.7);
  color: black;
  max-width: 406px;
  text-align: left;
  padding-top: 22px;
  padding-bottom: 0px;
  position: relative;

  .date_actu_slider {
    color: #c22323;
    font-family: $font-raleway;
    font-weight: bold;
    font-size: px2rem(16);
    padding-left: 21px;
    padding-right: 21px;
    padding-bottom: 5px;
  }

  .titre_actu_slider {
    font-family: $font-raleway;
    font-weight: bold;
    font-size: px2rem(26);
    padding-left: 21px;
    padding-right: 21px;
    padding-bottom: 5px;
  }

  .desc_actu_slider {
    font-family: $font-raleway;
    font-size: px2rem(16);
    padding-left: 21px;
    padding-right: 21px;
    padding-bottom: 5px;
  }

  .link_actu_slider {
    justify-content: end;
  }

  .lien-filets {
    display: inline-block;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: 15px;
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.carousel-item img {
  width: 100%;
  height: 620px;
  object-fit: cover;

  @media (max-width: 767px) {
    height: 620px!important;
    
  }

  @media (max-width: 1199.98px) {
    height: 390px;
  }
}

.carousel-control-next, .carousel-control-prev {
  width: initial;
  opacity: 1 !important;
}

.carousel-control-next svg, .carousel-control-prev svg {
  height: 62px;
  width: 30px;
}

.slider_nb_1 {
  .carousel-control-next, .carousel-control-prev {
    display: none !important;
  }
}

.carousel-control-prev {
  margin-left: 30px;
}

.carousel-control-next {
  margin-right: 30px;
}


.contenu_actu {
  padding-left: 30px;
  padding-right: 20px;
  padding-bottom: 20px;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 5px 5px 0px #a7a7a7;
  width: 100%;

  a {
    text-decoration: none;
  }

  .date_actu {
    color: #c22323;
    font-family: $font-raleway;
    font-weight: bold;
    font-size: px2rem(18);
    padding-bottom: 5px;
  }

  .titre_actu {
    font-family: $font-raleway;
    font-weight: bold;
    font-size: px2rem(24);
    padding-bottom: 5px;
  }

  .desc_actu {
    font-size: px2rem(14);
    padding-bottom: 5px;
  }
}

.espacement_actu {
  margin-bottom: 20px;
}

.bloc_actu {
  position: relative;
  height: 100%;
  padding-top: 55%;
  display: flex;
}

.type_actu {
  font-family: $font-bebasneue;
  font-size: px2rem(28);
  color: white;
  background-color: black;
  padding-left: 20px;
  padding-right:20px;
  position: absolute;
  top: 0;
  padding-top: 5px;
}


.titre_page_actu {
  font-family: $font-lustslim;
  font-size: px2rem(54);
  font-feature-settings: "kern" on, "liga" on, "rlig" on, "calt" on, "ss01" on;
  padding-left: 10px;
  line-height: 1.2em;

  &::first-letter {
    font-feature-settings: "kern" on, "liga" off, "rlig" off, "calt" off, "onum" on, "pnum" on, "ss01" on;
    font-size: 80px;
  }
}

.titre_page {
  margin-top: 50px;
  margin-bottom: 50px;
}

.bandeau_revue {
  font-family: $font-lustslim;
  background-color: white;
  padding-top: 25px;
  padding-bottom: 25px;
  padding-left: 130px;
  padding-right: 130px;
  display: flex;
  align-items: center;


  .bandeau_revue-btn {
    text-align: center;
    flex-grow: 1;
  }

  .bandeau_revue-texte {
    font-size: px2rem(54);
    line-height: 1.2em;
    flex-grow: 1;
    font-feature-settings: "kern" on, "liga" on, "rlig" on, "calt" on, "ss01" on;
    margin-bottom: -5px;
    text-align: center;
  }

  &::first-letter {
    font-feature-settings: "kern" on, "liga" off, "rlig" off, "calt" off, "onum" on, "pnum" on, "ss01" on;
    font-size: 80px;
  }

}

.bandeau_fond {
  background: url("../../img/bandeau_revue.png");
  padding-top: 62px;
  padding-bottom: 62px;
  padding-left: 115px;
  padding-right: 115px;
  margin-top: 10px;
  margin-bottom: 30px;

  @media (max-width: 767px){
    padding-left: 0px;
    padding-right: 0px;
  }
}

.button_bandeau_revue {
  display: inline-block;
  font-size: px2rem(18);
  font-family: $font-raleway;
  font-weight: bold;
  border: 0px solid black;
  padding-right: 55px;
  padding-left: 55px;
  padding-top: 15px;
  padding-bottom: 15px;
  text-transform: uppercase;
  color: white;
  background: linear-gradient(to right, #005576, #bcbfaa);
  vertical-align: middle;
}


.col-form-actu {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.bandeau_revue a {
  text-decoration: none;
}

.bandeau_revue a:hover {
  color: white;
  text-decoration: none;
}

#form_trier_par #div_valider {
  display: none;
}

#form_trier_par select {
  width: 300px;
  color: #a19f9f;
  background-color: #fff;
  padding: 12px;
  font-family: $font-raleway;
  font-style: italic;
  border: 1px solid #a19f9f;
  height:40px;
}

#form_trier_par {
  float: right;
}

#div_trier_par {
  display: flex;
  flex-direction: row;
}

#label_trier_par {
  padding-top: 12px;
  padding-right: 12px;
  font-size: px2rem(16);
  font-weight: bold;
  font-family: $font-raleway;
}

.lien_cache {
  display: none;
}

.lien_plus_articles {
  margin-top: 60px;
  margin-bottom: 60px;
  margin-left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
}

.bloc_actu_hidden {
  display: none;
}

.actu3 {
  display: none;
}

.contenu_actu a:before {
  content: '';
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
}

