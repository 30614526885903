
.center-cropped {
  background-position: center center;
  background-repeat: no-repeat;
  overflow: hidden;
  background-size: cover;
  height: 200px;
  display: block;
  position: relative;

  img {
    min-height: 100%;
    min-width: 100%;
    /* IE 8 */
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    /* IE 5-7 */
    filter: alpha(opacity=0);
    /* modern browsers */
    opacity: 0;
  }
}

.label-info {
  display: block;
  font-family: $font-bebasneue;
  font-weight: normal;
  text-transform: uppercase;
  font-size: 18px;
  padding: 1px 6px;
  color: #ffffff;
  background: #5bc0de;
}

.label-color-bleu-vert {
  background: #005576;
  background-color: #005576;
  background-image: linear-gradient(to right, #005576, #ade4b6);
}

.label-color-jaune-marron {
  background: #ffcd75;
  background-color: #ffcd75;
  background-image: linear-gradient(to right, #ffcd75, #a59b72);
}

.label-color-rose-violet {
  background: #fdaac1;
  background-color: #fdaac1;
  background-image: linear-gradient(to right, #fdaac1, #ae8fbc);
}

.label-color-bleu-violet {
  background: #005577;
  background-color: #005577;
  background-image: linear-gradient(to right, #005577, #c2baf4);
}

.label-color-bleuclair-gris {
  background: #1ea8db;
  background-color: #1ea8db;
  background-image: linear-gradient(to right, #1ea8db, #c6c5c6);
}

.label-color-vert-vert {
  background: #c3d26b;
  background-color: #c3d26b;
  background-image: linear-gradient(to right, #c3d26b, #3b772f);
}

.label-color-magenta-gris {
  background: #dc3c5a;
  background-color: #dc3c5a;
  background-image: linear-gradient(to right, #dc3c5a, #c2b4b6);
}

.label-color-rouge-jaune {
  background: #d4343f;
  background-color: #d4343f;
  background-image: linear-gradient(to right, #d4343f, #fdd1aa);
}

.label-color-jaune-orange {
  background: #fffa7f;
  background-color: #fffa7f;
  background-image: linear-gradient(to right, #fffa7f, #feac7f);
}

.label-color-violet-gris {
  background: #8e51b4;
  background-color: #8e51b4;
  background-image: linear-gradient(to right, #8e51b4, #c1c1c1);
}

a.movie_access {
  position: relative;
  display: block;
  margin-bottom: 25px;

  .movie-frame {
    display: block;
    background-image: url("../../img/landingpage/motif-2.png");
    background-repeat: repeat;
    background-position: center;
    left: 0px;
    right: 0px;
    top: 0;
    bottom: 0;
    position: absolute;
    visibility: hidden;
  }

  .movie-details {
    position: absolute;
    display: block;
    top: 5px;
    left: 5px;
    z-index: 1;

    .movie-favoris {
      background: transparent;
      border: none;
      padding: 0;
      margin: 0;
      visibility: hidden;

      &:hover {
        opacity: 0.8;
      }
    }

    .movie-location {
      display: inline-block;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      background-color: #000000;
      color: #ffffff;
      line-height: 35px;
      text-align: center;
      font-size: 12px;
      font-family: Raleway;
      font-weight: bold;
      vertical-align: middle;
    }
  }

  .movie-description {
    position: absolute;
    display: block;
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
    padding: 45px 15px 15px 15px;
    max-height: 100%;
    overflow: hidden;
    left: 0px;
    right: 0px;
    bottom: 0;
    color: #ffffff;

    span.movie-titre {
      font-family: $font-lustslim;
      font-size: 21px;
      margin-top: 0px;
      margin-bottom: 5px;
      display: block;
    }

    span.movie-directors {
      font-weight: bold;
      font-size: 14px;
      margin: 0 0 1px;
      display: block;
    }

    span.movie-accroche {
      font-weight: 500;
      font-size: 12px;
      margin-bottom: 0;
      display: none;

      p {
        font-size: 12px;
      }
    }
  }

  .movie-tooltip {
    visibility: hidden;
    color: #ffffff;
    position: absolute;
    display: block;
    right: 0;
    top: 0;
    /* Position the tooltip text - see examples below! */
    z-index: 1;

    span.label-info {
      display: inline-block;
      float: right;
      font-size: 18px;
      padding: 6px 6px 5px;
      background: #000000;

      &:after {
        content: " ";
        display: table;
        clear: both;
      }
    }

    span.movie_year_time {
      font-weight: 500;
      display: inline-block;
      float: right;
      font-size: 12px;
      padding: 2px 6px;
    }
  }

  &:hover {
    .movie-frame, .movie-tooltip, .movie-details .movie-favoris {
      visibility: visible;
    }

    .movie-description {
      span.movie-accroche {
        display: block;
      }
    }
  }
}

header {
  /*margin-top: 110px;*/
  padding: 0;
  background-color: #ffffff;
  box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.3);
  height: 80px;
  transition: all 0.3s ease-in-out;

  #header_logo {
    transition: all 0.3s ease-in-out;

    a {
      text-decoration: none;
      font-size: 10px;
      display: inline-block;

      img {
        transition: all 0.3s ease-in-out;
        height: 70px;
      }

      #header_logo_baseline {
        transition: opacity 0.3s ease-in-out;
        display: inline-block;
        vertical-align: middle;
        padding-left: 15px;
        font-weight: bold;
        white-space: pre;
        opacity: 1;
      }
    }
  }

  #header_try {
    transition: all 0.3s ease-in-out;
    text-align: right;
    text-transform: uppercase;
    font-size: 16px;
    font-family: $font-raleway;

    a {
      font-weight: bold;
      text-decoration: none;
      white-space: pre;
      text-align: center;
      display: block;
      color: #FFFFFF;
      padding: 14px 5px;
      background: #f68670; /* Old browsers */
      background: -moz-linear-gradient(left, #f68670 0%, #f8ab9b 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(left, #f68670 0%, #f8ab9b 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to right, #f68670 0%, #f8ab9b 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f68670', endColorstr='#f8ab9b', GradientType=1); /* IE6-9 */
      &:hover {
        opacity: 0.8;
      }
    }
  }

  #header_menu {
    #header_nav {
      padding: 0;

      #header-toggler {
        padding: 0;

        #header-navbtn {
          display: block;
          width: 16px;
          height: 20px;
          background: transparent url("../../img/landingpage/menu.png") -16px 0 no-repeat;
        }

        &.collapsed {
          #header-navbtn {
            background-position: 0 0;
          }
        }
      }

      #header-navbar {
        ul {
          li {
            padding-right: 5px;
            padding-right: 5px;

            a {
              font-size: 18px;
              font-family: $font-bebasneue;
              font-weight: normal;
              text-decoration: none;

              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
  }

  &.resized {
    height: 70px;

    #header_logo {
      a {
        img {
          height: 50px;
        }

        #header_logo_baseline {
          opacity: 0;
        }
      }
    }
  }

  @include media-breakpoint-down(sm) {
    #header_menu {
      #header-navbar {
        position: fixed;
        left: 0;
        top: 70px;
        background: #FFFFFF;
        width: 100%;

        ul {
          padding: 20px 10px;
        }
      }
    }
  }


  @include media-breakpoint-down(xs) {
    height: 70px;

    #header_logo {
      a {
        img {
          height: 50px;
        }

        #header_logo_baseline {
          display: none;
        }
      }
    }

    #header_try {
      a {
        font-size: 12px;
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }
  }
}

footer {

  #footer_bottom {
    background: transparent url(../../img/landingpage/motif.png) left top repeat;
  }

  #footer_bottom_bg {
    background: transparent url(../../img/landingpage/bref-footer.png) left top no-repeat;
    padding: 30px 0 10px 0;
  }

  #footer_editby {
    text-align: center;
    padding-bottom: 20px;

    a {
      font-size: 12px;
      font-weight: bold;
      text-decoration: none;
      display: inline-block;

      span {
        display: inline-block;
        padding-top: 3px;
      }

      img {
        margin-left: -10px;
        vertical-align: top;
      }
    }
  }

  #footer_newsletter {
    padding-bottom: 20px;

    #footer_newsletter_content {
      max-width: 190px;
      margin: 0 auto;
      position: relative;

      p {
        color: #000000;
        font-weight: bold;
        font-size: 12px;
        text-transform: uppercase;
        padding-bottom: 8px;
      }

      form {
        .form-group {
          margin-bottom: 0;
        }

        button.btn, input.form-control {
          border-radius: 0px;
          display: inline-block;
          vertical-align: middle;
        }

        input.form-control {
          width: auto;
          width: calc(100% - 32px);
          border: 0 none;
        }

        button.btn {
          width: 32px;
          padding: 5px 3px;
          text-align: center;
          text-transform: uppercase;
          background-color: #000000;
          color: #ffffff;

          &:hover {
            color: #000000;
            background-color: #ffffff;
          }

          &:active, &:focus {
            color: #cccccc;
          }
        }
      }

    }
  }

  #footer_follow {
    padding-bottom: 20px;
    text-align: center;
    font-size: 12px;

    p {
      display: inline;
      font-weight: bold;
      text-transform: uppercase;
      font-size: 12px;
    }

    ul {
      display: inline-block;
      font-size: 12px;

      li {
        display: inline-block;
        font-size: 12px;
        padding-left: 15px;

        a {
          display: block;

          img {
            max-height: 15px;
          }
        }
      }
    }
  }
}

#main {
  padding-top: 80px;
  min-height: 100%;

  @include media-breakpoint-down(xs) {
    padding-top: 70px;
  }
}

#lp-owl {
  .lp-slide {
    height: 620px;
    background-size: cover;
    background-position: center center;

    .lp-slide-content {
      padding-bottom: 30px;
      color: #FFFFFF;

      h1 {
        font-family: $font-lustslim;
        font-size: 46px;
      }

      p {
        font-size: 18px;
        font-family: $font-raleway;
        padding-bottom: 15px;
      }

      a.lp-slide-link {
        font-family: $font-raleway;
        font-size: 18px;
        font-weight: bold;
        text-transform: uppercase;
        text-decoration: none;
        white-space: pre;
        text-align: center;
        display: inline-block;
        color: #FFFFFF;
        padding: 14px 30px;
        background: #f68670; /* Old browsers */
        background: -moz-linear-gradient(left, #f68670 0%, #f8ab9b 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(left, #f68670 0%, #f8ab9b 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right, #f68670 0%, #f8ab9b 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f68670', endColorstr='#f8ab9b', GradientType=1); /* IE6-9 */
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }

  .owl-nav {
    margin-top: 0;
  }

  .owl-prev {
    width: 30px;
    height: 62px;
    position: absolute;
    top: 40%;
    left: 30px;
    display: block !important;
    border: 0px solid black;

    &:focus {
      outline: none;
    }
  }

  .owl-next {
    width: 30px;
    height: 62px;
    position: absolute;
    top: 40%;
    right: 30px;
    display: block !important;
    border: 0px solid black;

    &:focus {
      outline: none;
    }
  }

  @include media-breakpoint-down(lg) {
    .lp-slide {
      height: 390px;
    }
  }

  @include media-breakpoint-down(md) {
    .lp-slide {
      height: 320px;
    }
  }

  @include media-breakpoint-down(xs) {
    .owl-prev {
      left: 0px;
    }
    .owl-next {
      right: 0px;
    }
    .lp-slide {
      height: 300px;

      .lp-slide-content {
        h1 {
          font-size: 30px;
        }

        p {
          font-size: 14px;
        }

        a.lp-slide-link {
          font-size: 12px;
          padding: 10px 20px;
        }
      }
    }
  }
}

#lp-paras {
  padding-top: 20px;
  padding-bottom: 25px;

  h2 {
    font-size: 40px;
    font-family: $font-lustslim;
    padding-bottom: 12px;

    &::first-letter {
      font-feature-settings: "kern" on, "liga" off, "rlig" off, "calt" off, "onum" on, "pnum" on, "ss01" on;
      font-size: 80px;
    }
  }

  .lp-para {
    padding-bottom: 25px;

    .lp-para-img {
      text-align: center;
      min-height: 61px;
      padding-bottom: 12px;

      img {
        max-width: 100%;
      }
    }

    h3.lp-para-titre {
      font-size: 19px;
      text-transform: uppercase;
      font-family: $font-bebasneue;
      font-weight: normal;
      min-height: 51px;
      padding-bottom: 5px;
    }

    .lp-para-text {
      audio,
      img,
      video {
        max-width: 100%;
      }

      a {
        text-decoration: underline;
        color: #000000;

        &:hover {
          text-decoration: none;
        }
      }

      ol,
      ul {
        font-size: 14px;
        padding-bottom: 0;
        padding-left: 15px;
      }

      ul {
        li {
          font-size: 14px;
          padding-bottom: 0;
          list-style: disc outside;

          ul {
            font-size: 14px;
            padding-left: 15px;
            padding-bottom: 0;

            li {
              list-style: circle outside none;
            }
          }
        }
      }

      ol {
        li {
          font-size: 14px;
          list-style: decimal;
          list-style-position: outside;
          padding-bottom: 0;

          ol {
            font-size: 14px;
            padding-left: 15px;
            padding-bottom: 0;
          }
        }
      }

      p {
        font-size: 14px;
        padding-bottom: 0;

        ol,
        ul {
          font-size: 14px;
          padding-bottom: 0;
        }
      }
    }
  }

  @include media-breakpoint-down(xs) {
    h2 {
      font-size: 26px;
    }

    .lp-para {
      .lp-para-img {
        min-height: auto;
        padding-bottom: 12px;
      }

      h3.lp-para-titre {
        min-height: auto;
      }
    }
  }
}

#lp-une {
  background: transparent url("../../img/landingpage/motif.png") left top repeat;
  padding-top: 20px;
  padding-bottom: 25px;

  h2 {
    font-size: 40px;
    font-family: $font-lustslim;
    padding-bottom: 0;

    &::first-letter {
      font-feature-settings: "kern" on, "liga" off, "rlig" off, "calt" off, "onum" on, "pnum" on, "ss01" on;
      font-size: 80px;
    }
  }

  @include media-breakpoint-down(xs) {
    h2 {
      font-size: 26px;
    }

  }
}

#lp-offres {
  padding-top: 20px;
  padding-bottom: 20px;

  h2 {
    font-size: 40px;
    font-family: $font-lustslim !important;
    padding-bottom: 0;
    text-transform: initial;

    &::first-letter {
      font-feature-settings: "kern" on, "liga" off, "rlig" off, "calt" off, "onum" on, "pnum" on, "ss01" on;
      font-size: 80px;
    }
  }

  p#lp-offre-subtitle {
    font-family: $font-bebasneue;
    font-size: 19px;
    text-transform: uppercase;
    padding-bottom: 25px;
  }

  h3.lp-offre-bloc-titre {
    text-align: center;
    font-size: 24px;
    font-family: $font-lustslim !important;
    font-weight: normal;
    padding-bottom: 5px;
    text-transform: lowercase;

    &::first-letter {
      text-transform: uppercase;
    }
  }

  .lp-offre {
    padding-bottom: 20px;

    .lp-offre-top {
      background: #FA8573;
      background-color: #FA8573;
      background-image: url("../../img/landingpage/motif-4.png");
      background-image: url(../../img/landingpage/motif-4.png), linear-gradient(90deg, #f59383 10%, #f6aa9c);
      padding: 20px 20px 0 20px;
      color: #FFFFFF;
      min-height: 308px;
      margin-bottom: 10px;

      .lp-offre-periode {
        font-family: $font-bebasneue;
        font-weight: bold;
        font-size: 19px;
        padding-bottom: 10px;
      }

      .lp-offre-prix {
        font-size: 30px;
        font-weight: bold;
        font-family: $font-raleway;
        padding-bottom: 7px;
      }

      .lp-offre-condition {
        font-size: 1.6rem;
        text-transform: uppercase;
        font-weight: bold;

        a {
          text-decoration: underline;
          color: #FFFFFF;

          &:hover {
            text-decoration: none;
          }
        }

        ol,
        ul {
          font-size: 18px;
          padding-bottom: 0;
          padding-left: 15px;
        }

        ul {
          li {
            font-size: 18px;
            padding-bottom: 0;
            list-style: disc outside;

            ul {
              font-size: 18px;
              padding-left: 15px;
              padding-bottom: 0;

              li {
                list-style: circle outside none;
              }
            }
          }
        }

        ol {
          li {
            font-size: 18px;
            list-style: decimal;
            list-style-position: outside;
            padding-bottom: 0;

            ol {
              font-size: 18px;
              padding-left: 15px;
              padding-bottom: 0;
            }
          }
        }

        p {
          font-size: 18px;
          padding-bottom: 0;

          ol,
          ul {
            font-size: 18px;
            padding-bottom: 0;
          }
        }
      }

      .lp-offre-text {
        padding-bottom: 5px;

        a {
          text-decoration: underline;
          color: #FFFFFF;

          &:hover {
            text-decoration: none;
          }
        }

        ol,
        ul {
          font-size: 12px;
          padding-bottom: 0;
          padding-left: 15px;
        }

        ul {
          li {
            font-size: 12px;
            padding-bottom: 5px;
            list-style: disc outside;

            ul {
              font-size: 12px;
              padding-left: 15px;
              padding-bottom: 0;

              li {
                list-style: circle outside none;
              }
            }
          }
        }

        ol {
          li {
            font-size: 12px;
            list-style: decimal;
            list-style-position: outside;
            padding-bottom: 0;

            ol {
              font-size: 12px;
              padding-left: 15px;
              padding-bottom: 0;
            }
          }
        }

        p {
          font-size: 12px;
          padding-bottom: 0;

          ol,
          ul {
            font-size: 12px;
            padding-bottom: 0;
          }
        }
      }

      .lp-offre-precision {
        font-size: 14px;
        padding-bottom: 20px;

        a {
          color: #000000;
          text-decoration: underline;

          &:hover {
            text-decoration: none;
          }
        }
      }
    }

    .lp-offre-bottom {
      background: transparent url("../../img/landingpage/motif.png") left top repeat;
      padding: 14px 23px;

      a, span {
        text-align: center;
        display: block;
        background: #FFFFFF;
        color: #000000;
        font-family: Raleway;
        font-weight: bold;
        font-size: 14px;
        text-transform: uppercase;
        padding: 11px 0;
        text-decoration: none;
}
      a {
        transition: ease 0.3s;
        -webkit-transition: ease 0.3s;
        -moz-transition: ease 0.3s;
        -ms-transition: ease 0.3s;
        -o-transition: ease 0.3s;
      }

      a:hover {
        background: #000000;
        color: #FFFFFF;
      }

      span {
        background: none;
      }
    }


    &#lp-offre-1 {
      .lp-offre-top {
        background: #71AD97;
        background-color: #71AD97;
        background-image: url("../../img/landingpage/motif-4.png");
        background-image: url("../../img/landingpage/motif-4.png"), linear-gradient(to right, #71AD97 10%, #AACBC7);
      }
    }

    &#lp-offre-2 {
      .lp-offre-top {
        background: #005576;
        background-color: #005576;
        background-image: url("../../img/landingpage/motif-4.png");
        background-image: url("../../img/landingpage/motif-4.png"), linear-gradient(to right, #005576, #bbbfab);
      }
    }
  }

  @include media-breakpoint-down(xs) {
    h2 {
      font-size: 26px;
    }
  }
}

#lp-asterisque {
  padding-top: 20px;
  color: #000000;

  a {
    text-decoration: underline;
    color: #FFFFFF;

    &:hover {
      text-decoration: none;
    }
  }

  ol,
  ul {
    font-size: 10px;
    padding-bottom: 20px;
    padding-left: 15px;
  }

  ul {
    li {
      font-size: 10px;
      padding-bottom: 0;
      list-style: disc outside;

      ul {
        font-size: 10px;
        padding-left: 15px;
        padding-bottom: 0;

        li {
          list-style: circle outside none;
        }
      }
    }
  }

  ol {
    li {
      font-size: 10px;
      list-style: decimal;
      list-style-position: outside;
      padding-bottom: 0;

      ol {
        font-size: 12px;
        padding-left: 15px;
        padding-bottom: 0;
      }
    }
  }

  p {
    font-size: 10px;
    padding-bottom: 20px;

    ol,
    ul {
      font-size: 10px;
      padding-bottom: 0;
    }
  }
}

#lp-page {
  height: 220px;
  background-position: center center;
  background-repeat: no-repeat;
  overflow: hidden;
  background-size: cover;

  p {
    font-size: 40px;
    color: #FFFFFF;
    font-family: $font-lustslim;
  }

  @include media-breakpoint-down(xs) {
    height: 150px;
    p {
      font-size: 21px;
    }
  }
}

.lp-offre-text {
  .text_abo_titre {
    font-size: 1.6rem;
    text-transform: uppercase;
    font-weight: 700;
  }

  .list_abo {
    padding-top: 10px;
  }
}

.lp-offre-lien-abo-etudiant {
  a {
    color: black;
    font-weight: bold;
  }
}
