.titre_bloc_sortie_semaine {
  font-family: $font-lustslim;
  font-size: px2rem(50);
  font-feature-settings: "kern" on, "liga" on, "rlig" on, "calt" on, "ss01" on;
  margin-top: 50px;
  padding-left: 12px;
  line-height: 1em;
  margin-bottom: 15px;

  @media (max-width: 575px) {
    font-size: px2rem(28);
    margin-left: 0px;
    margin-right: 17px;
    margin-bottom: 20px;
    margin-top: 45px;
  }


  &::first-letter {
    font-size: 80px !important;

    @media (max-width: 575px) {
      font-size: px2rem(62);
    }
  }
}

.slider_sortie_semaine .swiper-slide {
  margin-top: 10px !important;
  margin-bottom: 35px !important;
}

.texte_bloc_sortie_semaine {
  font-family: $font-raleway;
  font-size: px2rem(14);
  margin-left: 10px;

  @media (max-width: 992px) {
    margin-left: 17px;
    margin-right: 17px;
  }

  @media (max-width: 360px) {
    margin-bottom: 20px;
  }
}

.titre_bloc_evenement,
.titre_bloc_sortie,
.titre_bloc_cineaste,
.titre_bloc_plus_vus,
.titre_bloc_thematique,
.titre_bloc_bref,
.titre_bloc_vous_avez {
  display: inline-block;
  font-family: $font-lustslim;
  font-size: px2rem(50);
  line-height: 1em;
  font-feature-settings: "kern" on, "liga" on, "rlig" on, "calt" on, "ss01" on;
  padding-left: 12px;
  text-decoration: none;
  margin-top: 40px;
  margin-bottom: 15px;

  @media (max-width: 575px) {
    font-size: px2rem(28);
    margin-bottom: 30px;
    margin-top: 40px !important;
    margin-left: 5px;
  }

  &::first-letter {
    font-size: 80px !important;

    @media (max-width: 575px) {
      font-size: px2rem(62);
    }
  }
}


.titre_bloc_vous_avez {
  @media(max-width: 1022px) {
    margin-bottom: 0px !important;
  }
}

.titre_bloc_evenement {
  @media (max-width: 360px) {
  }
}

.titre_evenement {
  @media (max-width: 575px) {
    font-size: px2rem(32);
  }
}

.fond_evenement,
.fond_cineastes,
.fond_thematique,
.fond_bloc_bref,
.fond_slogan {
  background: url("../../img/bandeau_revue.png");
  padding-bottom: 20px;
}

.fond_bloc_bref {
  padding-bottom: 40px !important;
}

.lien_voir_plus {
  margin-left: 100%;
  transform: translateX(-110%);
  white-space: nowrap;
  margin-top: 20px;
}

.espacement_sortie:first-of-type,
.espacement_cineaste:first-of-type {
  margin-left: 117px;

  @media (max-width: 1199px) {
    margin-left: 50px;
  }

  @media (max-width: 991px) {
    margin-left: 0px;
  }
}

.bloc_evenement {
  @media (max-width: 575px) {
    height: 158px !important;
    min-height: unset;
  }

  transition: all .3s;

  &:hover {
    opacity: .9;
  }
}

.bloc_autour_sortie {
  margin-top: 10px;
  min-height: 400px;
}

.voir_plus_sortie {
  margin-bottom: 20px;
}

.espacement_cineaste:first-of-type {
  @media (max-width: 1140px) {
    margin-left: 0;
  }
}

.bloc_sortie .titre_sortie {
  font-size: px2rem(24) !important;
  font-family: $font-bebasneue;
  margin-top: 0px !important;
}

.titre_bloc_cineaste {
  margin-top: 30px;
  margin-bottom: 5px !important;
}

.espacement_cineaste {
  // margin-right: 20px;

}

.espacement_cineaste .bloc_cineaste {
  margin-bottom: 0px;
  text-decoration: none;
  background-size: cover;
}

.film {
  margin: 10px 0;

  a {
    display: block;
    position: relative;
    color: #ffffff;

    .film_image {
      img {
        max-width: 100%;
      }
    }

    .film_infos_top {
      display: block;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      padding: 12px 15px;

      .film_expiration {
        .expiration_picto {
          background: #000000;
          width: 28px;
          height: 28px;
          line-height: 28px;
          text-align: center;
          display: block;
          border-radius: 50%;
          font-size: 0.9em;
        }
      }

      .film_date_duree {
        font-size: 1.2em;
      }
    }

    .film_infos_bottom {
      display: block;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 12px 15px;

      h3 {
        font-size: 2em;
        font-family: $font-lustslim;
        margin: 0;
      }

      h4.film_realisateur {
        margin: 0;
        font-size: 1.3em;
        font-weight: bold;
        padding-top: 6px;
      }

      .film_pictos {

        .picto_favoris {
          display: inline-block;

          button {
            display: block;
            background: url(../../img/picto_favoris.png) no-repeat center center;
            width: 30px;
            height: 30px;
            border: none;
          }
        }

        .picto_bonus {
          display: inline-block;
          background: url(../../img/picto_bonus.png) no-repeat center center;
          width: 30px;
          height: 30px;
        }
      }
    }
  }

}

.sortie_semaine a {
  overflow: hidden;
}

.sortie_semaine .film_image {
  width: 100%;
  height: 100%;
}

.sortie_semaine .film_image img {
  height: 100% !important;
  width: 100% !important;
}

.titre_bloc_plus_vus {
  margin-bottom: 0px !important;
}

.plus_vus {
  @media (max-width: 767px) {
    height: calc((68vw - 45px) / 1.33333 * 3 + 210px);
  }

  .swiper-slide {
    margin-top: 10px !important;
    margin-bottom: 0px !important;
  }

  .swiper-pagination {
    @media (max-width: 1022px) {
      bottom: 30px !important;
    }
  }
}

.titre_bloc_thematique {
  margin-bottom: 25px !important;
}

.bloc_thematique {
  min-height: 228px;
  padding-top: 56.25%;
  position: relative;
  background-size: cover;
  display: block;

  transition: all .3s;

  .titre_thematique {
    bottom: 0px;
    background-color: black;
    color: white;
    font-family: $font-bebasneue;
    font-size: px2rem(28);
    text-transform: uppercase;
    transition: all .3s;

    &:hover {
      background: #111111;
    }
  }

  &:hover {
    filter: brightness(110%);
  }
}


.button_bloc_bref {
  font-size: px2rem(18);
  font-family: $font-raleway;
  font-weight: bold;
  border: 0px solid black;
  padding-right: 55px;
  padding-left: 55px;
  padding-top: 15px;
  padding-bottom: 15px;
  text-transform: uppercase;
  color: white;
  background: linear-gradient(to right, #005576, #bcbfaa);
  text-decoration: none;

  transition: all .3s;

  &:hover {
    text-decoration: none;
    color: white;
    opacity: .8;
  }


  @media (max-width: 575px) {
    padding-left: 0px;
    padding-right: 0px;
  }


}

.bloc_bref_gauche {
  padding: 22px;
  background-color: white;
  /*min-height: 434px;*/
  display: flex;
  height: 100%;
  align-items: stretch;


  @media (max-width: 767px) {
    flex-wrap: wrap;

    img {
      max-width: 100% !important;
    }
  }


  img {
    max-width: 279px;
    margin-right: 20px;
    object-fit: contain;
    align-self: baseline;
  }

  .texte_bloc_bref_gauche {
    line-height: 20px;
    font-family: $font-raleway;
    font-size: px2rem(14);
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: baseline;


    > div {
      flex-grow: 1;
    }

  }

  .button_bloc_bref {

    flex-grow: 0;
    text-align: center;

    margin-top: 20px;

    @media (max-width: 767px) {
      width: 100%;
    }

  }
}

.bloc_bref_droite {
  background-color: white;
  padding: 22px;
  line-height: 20px;
  font-family: $font-raleway;
  font-size: px2rem(14);
  display: flex;
  flex-direction: column;
  height: 100%;

  > div {
    flex-grow: 1;
  }

  @media (max-width: 991px) {
    margin-top: 20px;
  }

  .button_bloc_bref {
    margin-top: 20px;
    text-align: center;
    margin-left: 10%;
    margin-right: 10%;
    padding-left: 20px;
    padding-right: 20px;


    @media (max-width: 767px) {
      margin: 0;
      width: 100%;
    }

  }
}

.sortie_semaine {

  .swiper-container-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    bottom: 35px;
    margin-bottom: 45px;
  }

  %transition_all_03s {
    transition: all .3s ease;
  }

  %backface_visibility_hidden {
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
  }

  *,
  *:before,
  *:after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  .swiper-container {
    width: 100%;

    transition: opacity .6s ease;

    @media (max-width: 767px) {
      height: calc((90vw - 20px) / 1.23357 * 3 + 135px);
    }

  }

  .swiper-wrapper {
    @media (max-width: 360px) {
      width: 340px !important;
    }
  }

  .swiper-slide-active {
    padding-left: 0px;
  }

  .swiper-slide {
    background-position: center;
    background-size: cover;
    /*height: 16vw;*/
    /*margin-right: 20px;*/


    @media (max-width: 767px) {

      padding-left: 0px;
      margin-bottom: 10px !important;
    }

    @media (max-width: 360px) {
      margin-left: 0px !important;
      margin-right: 0px !important;
    }

    .bloc_film_vignette {
      a {
        padding-top: 81%;
      }

      @media (max-width: 360px) {

        margin-right: 0px !important;
      }

    }

    .entity-img {
      display: none;
    }

    .content {
      position: absolute;
      top: 40%;
      left: 0;
      width: 64%;
      padding-left: 5%;
      color: #fff;

      .title {
        font-size: 1.8em;
        font-weight: bold;
        margin-bottom: 30px;
      }

      .caption {
        display: none;
        font-size: 12px;
        line-height: 1.4;
      }
    }
  }
}

.bloc_evenement {
  @media(max-width: 991px) {
    margin-bottom: 20px;
  }
}

.bloc_cineaste {
  @media(max-width: 991px) {
    margin-left: 50%;
    transform: translateX(-50%);
  }
}

.espacement_cineaste {
  @media (max-width: 1120px) {
    margin-bottom: 20px;
  }
}

.espacement_sortie:first-of-type {
  @media (max-width: 1120px) {
    margin-left: 0px;
  }
}

.espacement_sortie {
  margin-right: 20px;
}

.slider_mobile .espacement_sortie {
  display: flex;
  height: 100%;
}

.bloc_sortie {
  min-width: 194px;
  text-decoration: none;
}

.bloc_autour_sortie {

  @media (max-width: 1120px) {

    /* margin-left: 10px;*/
    .swiper-container {
      padding-left: 10px;
    }
  }

  .swiper-pagination {
    position: absolute !important;
  }

  @media(max-width: 1110px) {
    .swiper-slide {
      width: auto;
    }
  }

}

.blocs_cineastes {
  margin-left: 20px;

  @media (max-width: 575px) {
    margin-left: 50%;
    transform: translateX(-50%);
  }
}

.espacement_vous_avez {
  justify-content: space-between;
  margin-bottom: 55px;

  @media (min-width: 991px) {
    .col-lg-4 {
      flex: 0 0 calc(32.5%);
    }
  }

  @media (min-width: 768px) and (max-width: 991px) {
    .col-md-6 {
      flex: 0 0 calc(49%);
    }
  }
}

.bloc_vous_avez {
  position: relative;
  height: 229px;
  background-size: cover;

  @media (min-width: 768px) and (max-width: 991px) {
    margin-bottom: 20px;
  }

  @media (max-width: 768px) {
    margin: 10px;
  }

  @media (max-width: 360px) {
    height: 180px;
  }

  transition: all .3s;

  &:hover {
    opacity: .8;
  }

  .titre_vous_avez {
    position: absolute;
    bottom: 40%;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;


    h3,
    span {
      color: white;
      text-decoration: none;
      font-size: px2rem(30);
      font-family: $font-bebasneue;
    }


  }
}

.slogan {
  font-family: $font-lustslim;
  font-size: px2rem(28);
  line-height: 1em;
  margin: 0;
  padding: 0;
  text-align: center;
}

.fond_slogan {
  max-width: none !important;
  padding-top: 19px;
  padding-bottom: 12px;
}

#div_menu_footer-2 {
  @media (max-width: 360px) {
    margin-left: 50%;
    position: absolute;
    margin-top: 0px;
    padding-top: 0px;
    bottom: 0px;
  }
}

#div_menu_footer-3 {
  @media (max-width: 360px) {
    margin-top: 15px;
  }
}

.footer-logos {
  @media (max-width: 360px) {
    text-align: center;
  }
}

.footer--networks {
  a#pop_up_newsletter {
    margin-left: 12%;
    margin-right: 12%;
    padding-right: 20px !important;
    padding-left: 43px;
  }

  a {
    padding-right: 13px;
    padding-left: 20px;
  }
}

.slider_mobile {
  display: none;

  @media (max-width: 1110px) {
    display: block;
  }
}

.bloc_sortie_home {

  display: block;

  @media (max-width: 1110px) {
    display: none;
  }

}

.titre_bloc_plus_vus {
  margin-bottom: 10px !important;
}

.plus_vus .swiper-pagination-bullets {
  margin-top: 15px;
  margin-bottom: 25px;
}

.plus_vus .swiper-pagination {
  bottom: 70px !important;
  position: initial;
  @media (max-width: 576px) {
    position: inherit;
  }
}

.plus_vus .bloc_film_vignette {
  margin-top: 0px;
}

.bloc_autour_sortie {
  @media (max-width: 676px) {
    .swiper-container .swiper-wrapper .swiper-slide {
      margin-top: 20px !important;
    }
  }
}

.titre_bloc_sortie {
  @media (max-width: 575px) {
    margin-bottom: 0px !important;
    padding-bottom: 0 !important;
  }
}

@media (max-width: 576px) {
  #film_fiche #fiche_a_retrouver h2 {
    margin-bottom: 0 !important;
    padding: 10px 0 !important;
    margin-top: 0 !important;
  }

  .contour {
    background-position: center top;
    padding-top: 7px;
    margin-bottom: 0;
  }

  .container {
    padding-left: 20px;
    padding-right: 20px;

    h2 {
      padding: 0 10px 30px;
      margin-bottom: 0;
    }

    &-fluid {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .swiper-container {

    .swiper-wrapper {
      .swiper-slide {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
      }
    }
  }

  .bloc_cineaste {
    &:first-child {
      margin-top: 0;
    }
  }

  .titre_bloc_sortie_semaine {
    padding-bottom: 15px !important;
  }

  .titre_bloc_cineaste {
    margin-top: 30px;
    margin-bottom: 0 !important;
  }

  .lien_voir_plus {
    margin-top: 0px;
  }

  .titre_bloc_plus_vus {
    margin-bottom: 0 !important;
  }


  .bloc_film_vignette {
    margin: 0px 0 20px;
  }

  .voir_plus_sortie {
    margin-bottom: 30px;
    margin-top: 10px;
  }

  .titre_bloc_vous_avez {
    padding-bottom: 20px !important;
  }

  .plus_vus .swiper-pagination-bullets {
    margin-top: 15px;
    margin-bottom: 25px;
    padding-right: 0;
  }

  .titre_bloc_thematique {
    margin-bottom: 0 !important;
  }

  .fond_bloc_bref {
    padding-bottom: 80px;
  }

  .footer-menus {
    background: url(../../img/FOND.png) no-repeat center;
    background-size: cover;
    background-position-y: bottom;
  }

  .footer-logos a {
    padding-bottom: 40px;
  }

  #div_menu_footer-2 {
    margin-left: 20%;
  }

  .container-fluid.container-short {
    padding: 0 50px;
  }

  .slider_mobile .espacement_sortie {
    padding-bottom: 30px;
  }

  // .sortie_semaine .swiper-container-horizontal>.swiper-pagination-bullets,
  // .swiper-pagination-custom,
  // .swiper-pagination-fraction {
  //   display: none;
  // }

  // .bloc_autour_sortie .swiper-container-horizontal>.swiper-pagination-bullets,
  // .swiper-pagination-custom,
  // .swiper-pagination-fraction {
  //   display: none;
  // }

  h2.titre_bloc_thematique {
    padding-left: 0;
  }

  h2.titre_bloc_evenement {
  }


  .bloc_autour_sortie .swiper-container .swiper-wrapper .swiper-slide {
    margin-top: 0;
    padding-bottom: 10px;
  }

  .bloc_bref_gauche img {
    padding-bottom: 18px;
  }

  .footer--networks a#pop_up_newsletter {
    margin-left: 0;
    margin-right: 12%;
    padding-right: 0 !important;
    padding-left: 55px;
    margin-bottom: 25px;
  }

  .footer--networks a {
    margin-bottom: 5px;
    display: inline-block;
  }

  .bandeau_revue a {
    text-decoration: none;
    margin-top: 15px;
  }

  #form_trier_par {
    float: none;
  }

  #div_trier_par {
    flex-direction: column;
  }

  #form_trier_par select {
    width: 100%;

  }

  .titre_page_actu {
    margin-bottom: 30px;
  }

  .titre_page {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .texte_bloc_sortie_semaine {
    margin-bottom: 25px;
  }

  .plus_vus {
    height: calc((68vw - 45px) / 1.33333 * 3 + 210px);
  }

  .fond_cineastes .lien_voir_plus {
    padding-bottom: 30px;
  }

  .footer-logos {
    text-align: center;
  }

  ul#menu_footer-3 {
    margin-top: 20px;
  }

  .logo-agence {
    margin-top: 15px;

    a {
      padding-bottom: 20px;
    }
  }

  .swiper-container.autour_sorties_slider_page.swiper-container-initialized.swiper-container-horizontal.swiper-container-android,
  .swiper-container.autour_sorties_slider_page.swiper-container-initialized.swiper-container-horizontal.swiper-container-ios {
    margin-bottom: 40px;
    padding-bottom: 15px;

    .swiper-pagination {
      display: block !important;
      top: 28px;
      margin-bottom: 11px;

    }
  }

  #film_fiche #fiche_player {
    padding-bottom: 0;

    .popup-wrapper {
      position: absolute;
      display: block;
      width: 100%;
      left: 0;
      top: 0;
      margin-top: 0;
      z-index: 1;
      box-shadow: 0px 0px 4px #000;
      padding-top: 0;

      .mot_de_passe_oublie {
        a {
          color: #000000;
        }
      }

      .btn {
        color: #fff;
      }
    }
  }

  .swiper-slide.swiper-slide-active.contour {
    background-image: url(../../img/round_border.png);
    background-repeat: no-repeat;
    background-position-x: center;
    padding-top: 7px;
    margin-bottom: 0 !important;
  }

  .text_abo {
    font-size: 4.9rem;
    text-align: center;
  }

  #fiche_pictos {
    float: right;
  }

  #film_fiche #fiche_tools {
    padding-top: 7px;
    padding-bottom: 15px;
    text-align: right;
  }

  #carouselActu {
    .carousel-control-next {
      margin-right: 0;
    }

    .carousel-control-prev {
      margin-left: 0;
    }

    .carousel-caption {
      padding-top: inherit;
      padding-bottom: inherit;
      bottom: auto;
      top: 50%;
      transform: translateY(-50%);


    }

    .carousel-item.active {
      display: flex;
      align-items: center;
    }
  }

  .formules_btn_box {
    &.form-butons {
      display: block;
      text-align: center;

      > * {
        margin-left: 0;
      }
    }
  }

  .bloc_abo_groupe .contenu_bloc_abo_groupe .last_button {
    margin-bottom: 13px !important;
  }

  .bloc_abo_groupe .contenu_bloc_abo_groupe .button_demande_devis {
    margin-top: 0 !important;
    position: inherit !important;
    width: 100% !important;
  }

  .titre_partie_compte .form-butons {
    display: block !important;

    > * {
      margin-left: 0;
    }

    span {
      margin-bottom: 20px;
      display: inline-block;
    }
  }

  .bloc_thematique {
    min-height: auto !important;
    margin-bottom: 20px !important;
  }

  #tarteaucitronAlertBig {
    .bloc_Pie_center {
      width: 100%;
      margin: 0;
      text-align: left;

      #tarteaucitronCloseAlert, #tarteaucitronPrivacyUrl {
        margin: 0;
      }
    }

  }

}

.menu_burger .lesthematiques {
  &:hover {
    color: #005576;
  }
}

.popup-btn {
  a {
    color: #fff !important;
  }
}

.mot_de_passe_oublie {
  a {
    color: #000000 !important;
  }
}

.abo_active {
  color: #ffffff;
}

a.show_moreBtn {
  &:hover {
    cursor: pointer;
  }
}

.fond_thematique .bloc_evenement {
  margin-bottom: 20px !important;
}
