body,
html {
  background: #FFFFFF;
  color: #000000;
  margin: 0;
  font-family: $font-raleway;
}

html {
  font-size: 62.5%;
  /* Now 10px = 1rem! */
  line-height: 1.2 !important;
  height: 100%;
}

body {
  line-height: 1.2 !important;
  min-height: 100%;
  min-width: 320px;
}

div {
  font-size: 1.4rem;
}

a {
  color: #000000;
  text-decoration: underline;
}

a:hover {
  color: inherit;
}

.dehors {
  position: absolute;
  left: -50000000px;
  top: -5000000px;
}

.cacher {
  display: block;
  margin-top: -5000px;
  position: absolute !important;
  margin-left: -50000px;
  width: 1px;
  overflow: hidden;
  height: 1px;
}

img {
  border: 0;
}

.clearer {
  display: block;
  clear: both;
  font-size: 0;
  line-height: 0;
}


.lien-filets {
  @include lien-filets();
}

.lien-filets_white:hover {
  color: #ffffff;
  opacity: .8;
}

.lien-filets_white {
  @include lien-filets(false);
}

.container-fluid {
  max-width: 1140px;
}

#titres .container-fluid {
  max-width: initial;
}

.container-short {
  @include container-short();
}

#titres {
  text-align: center;
  //background: url(../../img/trame.png);
  padding-top: 40px;
  padding-bottom: 15px;
  //max-width: unset;
  //padding-left: 25%;

  ::first-letter {
    text-transform: capitalize;
  }
}

#titres h1 {
  line-height: 1em;
  text-align: left;
  font-size: px2rem(82);
  font-family: $font-lustslim;
  font-family: LustSlimDidone, Times, Georgia, serif;
  font-feature-settings: "kern" on, "liga" on, "rlig" on, "calt" on, "ss01" on;
}

.bandeau_titre.bandeau_titre--vert {
  background: url(../../img/bandeau_titre--vert.png);
}

button,
input[type=button],
input[type=submit] {
  cursor: pointer;
}

.bandeau_titre {
  margin-bottom: 20px;
}

.bandeau_titre #titres {
  padding-top: 10px;
  padding-bottom: 0;

}

.bandeau_titre #titres h1 {
  font-size: 4.2rem;
  color: #ffffff;
  padding: 0;
}

.paragraphe {
  @include container-short();
  margin-top: 40px;
  margin-bottom: 40px;
}

.paragraphe:empty {
  display: none;
}

p {
  font-family: $font-raleway;
  font-size: 1.4rem;
}


.share-links a {
  display: inline-block;
  padding: 5px;
}

.share-links img {
  max-height: 22px;
}

.share-links img[src*="twitter"] {
  height: 20px;
}

.btn {
  border: none;
  background: none;
  border-radius: 0;
}

.btn-gradient {
  font-weight: bold;
  text-decoration: none;
  white-space: pre;
  text-align: center;
  display: block;
  color: #FFFFFF;
  padding: 10px 20px;
  background: #f68670;
  background: linear-gradient(to right, #f68670 0%, #f8ab9b 100%);
  font-family: $font-raleway;
  font-size: px2rem(14);
  text-transform: uppercase;
  font-weight: bolder;
}


.btn-img {
  background: none;
  border: none;
  display: inline-block;
  padding: 10px;
  outline: none !important;
}


.header-buttons {
  display: flex;
  justify-items: flex-start;
}

.texte p a,
p a,
.texte a, .texte_bloc_bref_gauche li a {
  color: #fa8573 !important;
  text-decoration: underline !important;
}


.table {
  tbody {
    tr {
      background-color: #e6e6e6;
      border-bottom: white 10px solid;
    }
  }

  td,
  th {
    border-top: none;
  }

  thead {
    th {
      width: 10%;
      vertical-align: middle;
    }
  }

  td {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
    text-align: center;
    vertical-align: middle;
  }

  th {
    text-align: center;
  }

  th:first-of-type,
  td:first-of-type {
    text-align: unset;
  }

}

.button_gift_compte {
  width: 245px;
  margin-top: 20px;
}

.compte {

  padding-top: 30px;
  padding-bottom: 30px;

  .nav {
    @media (max-width: 767px) {
      margin-bottom: 30px;
    }


  }

  .nav-link {
    padding-top: 0;
    font-family: $font-bebasneue;
    text-transform: uppercase;
    font-size: px2rem(35);
    text-decoration: none;
    display: block;
    padding-left: 0;
    padding-bottom: 3px;
    margin-bottom: 15px;
    line-height: 1em;
    font-weight: lighter;
  }

  .nav-item.active > .nav-link {
    border-bottom: 3px solid;
    font-weight: 400;
  }

  h2 {
    font-size: px2rem(28);
    text-transform: uppercase;
    font-family: $font-bebasneue;
    font-weight: 400;
  }

  .titre_partie {
    font-size: px2rem(35);
    text-transform: uppercase;
    font-family: $font-bebasneue;
    font-weight: 400;
    padding-bottom: 20px
  }

  .informations {
    font-size: px2rem(28);
    text-transform: uppercase;
    font-family: $font-bebasneue;
    font-weight: normal;
    padding-bottom: 15px;
  }

  #compte-informations {
    h2 {
      font-size: px2rem(28);
      text-transform: uppercase;
      font-family: $font-bebasneue;
      font-weight: normal;

    }
  }

  .preferences {
    font-size: px2rem(18);
    font-family: $font-bebasneue;
    font-weight: normal;
    text-transform: none;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  h2.Mon.abonnement,
  h2.mes.films {
    display: none;
  }

}


/* Décalage nécessaire dû au header fixe */

#bloc_main_before {
  margin-top: 90px;
}

.swiper-container {
  position: inherit;


  .swiper-wrapper {
    display: flex;

    .swiper-slide {
      height: auto !important;
      margin-bottom: 45px;
      margin-top: 0;
    }
  }

  .swiper-pagination {
    text-align: right;
    left: unset;
    position: inherit;
  }

  .swiper-pagination-bullet-active {
    background-color: white;
    border: 1px solid;
  }

  .swiper-pagination-bullet {
    margin: 0 2px;
    opacity: unset;
  }

}

/* Recherche */

.btn_rechercher {
  position: absolute;
  margin-left: 50px;
  display: none;

  .form-popup {
    display: block;
  }

  .rechercher_pop_up {
    margin-top: 25px;

    @media(max-width: 767px) {
      margin-top: 5px;
    }
  }

  .input_rechercher {
    background-image: url("../../img/loupe.png");
    background-repeat: no-repeat;
    background-position-x: 10px;
    background-position-y: 8px;
    padding-left: 50px;
    height: 40px;
    background-color: #e6e6e6;
    z-index: 1;
    outline-offset: unset;
    font-size: px2rem(16);
    font-family: $font-raleway;
    font-style: italic;
    color: black;
    width: 175px;
    outline: none !important;
  }

  .input_rechercher:focus {
    outline-color: red;
  }

  .submit_rechercher {
    position: absolute;
    left: 100%;
    border: 0px solid black;
    height: 40px;
    color: white;
    background-color: black;
    font-family: $font-raleway;
    font-weight: bold;
    font-size: px2rem(14);
    text-transform: uppercase;
    padding: 10px;
    z-index: 1;
    margin-top: 0px;
  }
}

.container-lg {
  max-width: 1280px;
}

.container-md {
  max-width: 1055px;
}

.misob {
  font-family: $font-raleway;
  font-weight: bold;
  font-size: px2rem(23);
  margin-top: 30px;
  margin-bottom: 30px;
}

.recherche_film_titre,
.recherche_article_titre {
  font-family: $font-bebasneue;
  font-size: px2rem(38);
  text-transform: uppercase;
}

.recherche_film {
  margin-bottom: 55px;
}

.recherche_actu {
  margin-bottom: 55px;
}

.pop_up_recherche_visible {
  display: block !important;
}

/* Menu burger + personnalisation scrollbar */

.menu_burger {
  position: absolute;
  left: 0;
  background-color: white;
  z-index: 1032;
  width: 300px;
  padding-right: 28px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #8e8e8e #dadada;
  transition: all .3s;
  transform: none;
  opacity: 1;
  visibility: visible;
  max-height: 100vh;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #dadada;
  }

  &::-webkit-scrollbar-thumb {
    background: #8e8e8e;
  }

  .croix_menu_burger {
    background: url("../../img/croix.png");
    height: 16px;
    margin-top: 38px;
    margin-left: 28px;
    margin-bottom: 25px;
  }

  ul {
    list-style-type: none;
    padding-inline-start: 28px;
  }

  > li ul {
    padding-inline-start: 20px;
  }

  li ul li ul {
    padding-inline-start: 0px !important;
  }

  a,
  .lesfilms,
  .lesthematiques,
  .menu_actu,
  .menu_acheter,
  .menu_sabonner,
  .menu_seconnecter {
    text-decoration: none;
    color: black;
    font-size: px2rem(21);
    text-transform: uppercase;
    font-family: $font-bebasneue;

    transition: all .3s;


  }

  .lesfilms,
  .menu_actu,
  .menu_acheter,
  .menu_sabonner,
  .menu_seconnecter {
    &:hover {
      opacity: .5 !important;
      background-image: linear-gradient(to right, #000, #000) !important;
    }
  }

  .menu_burger_liens a:hover,
  .menu_rs a:hover {
    opacity: .5;
  }

  .lesfilms {
    padding-bottom: 3px;
    background-position-y: 100%;
    background-repeat: no-repeat;
    background-size: 100% 3px;
    background-image: linear-gradient(to right, $menu-dark-blue, $menu-dark-blue-end);

  }

  .lesfilms_actif,
  .lesfilms.selected {
    color: $menu-dark-blue;
  }

  .li_niv_2,
  .li_niv_3 {
    display: none;
  }


  .li_niv_3 a {
    font-family: $font-raleway;
    font-weight: bold;
    font-size: px2rem(14);
    line-height: 1.5;

    &:hover {
      color: #005576;
    }
  }

  .li_niv_2_actif,
  .li_niv_3_actif {
    display: inherit;
  }

  .li_niv_2 > .selected,
  .li_niv_2 > a:hover,
  .li_niv_2 > div:hover {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      left: -30px;
      border-top: 3px solid #005576;
      width: 20px;
      top: 50%;
    }
  }

  .li_niv_2_actif {
    line-height: 1.5;
  }

  ul li ul li ul li {
    padding-inline-start: 28px;
  }

  ul li ul li ul li:hover,
  .li_niv_3.selected {
    background: url(../../img/rectangle_bleu.png);
    background-repeat: no-repeat;
    background-position-x: left;
    padding-left: 28px;
    background-position-y: center;
    padding-inline-start: 28px;
  }

  .menu_actu {
    padding-bottom: 3px;
    background-position-y: 100%;
    background-repeat: no-repeat;
    background-size: 100% 3px;
    background-image: linear-gradient(to right, $menu-red, $menu-red-end);
  }

  .menu_seconnecter {
    padding-bottom: 3px;
    background-position-y: 100%;
    background-repeat: no-repeat;
    background-size: 100% 3px;
    background-image: linear-gradient(to right, $menu-green, $menu-green-end);
  }

  .menu_sabonner {
    padding-bottom: 3px;
    background-position-y: 100%;
    background-repeat: no-repeat;
    background-size: 100% 3px;
    background-image: linear-gradient(to right, $menu-pink, $menu-pink-end);
  }

  .menu_acheter {
    padding-bottom: 3px;
    background-position-y: 100%;
    background-repeat: no-repeat;
    background-size: 100% 3px;
    background-image: linear-gradient(to right, $menu-dark-blue, $menu-dark-blue-end);
  }

  .menu_rs {
    padding-top: 12px;
    padding-left: 28px;

    img {
      height: 22px;
      padding-right: 20px;
      margin-bottom: 20px;
    }
  }

  .btn_rechercher {
    margin-bottom: 45px;
    position: initial;
    margin-left: 28px;
  }

  .input_rechercher {
    width: 200px;
  }

  .form-popup {
    position: initial;
  }

  .rechercher_pop_up {
    margin-top: 0px;
  }

  .submit_rechercher {
    position: initial;
  }

}

.menu_burger_cacher {
  visibility: hidden;
  opacity: 0;
  transform: translateX(-10px);
}

.menu_burger .li_niv_2 > a:hover,
.menu_burger .lesfilms:hover {
  color: $menu-dark-blue;
}

.menu_burger .menu_actu:hover {
  color: #d42323;
}

.menu_burger .menu_seconnecter:hover {
  color: #4fad9a;
}

.menu_burger .menu_acheter:hover {
  color: #005576;
}

.menu_burger .menu_sabonner:hover {
  color: #f38273;
}

.menu_burger_liens a {
  font-family: $font-raleway;
  font-size: px2rem(13);
  font-weight: bold;
  text-transform: uppercase;
}

.menu_burger_liens {
  margin-top: 12px;
}

.lesfilms,
.lesthematiques {
  cursor: pointer;
}

.damier {
  background: url(../../img/damier-gris.png);

}


.credit-card {

  width: 305px;
  border-radius: 5px;
  background: #83c5b9;
  background: linear-gradient(30deg, #83C5B9, #83C5B9 50%, #A3EADC 50.1%, #A3EADC 100%);
  padding-left: 15px;
  padding-right: 15px;

  padding-top: 50px;
  margin-top: 20px;
  margin-bottom: 20px;

  > div {
    padding: 5px;
    background: rgba(255, 255, 255, .6);
    margin-bottom: 10px;
    text-transform: uppercase;
    font-weight: bold;
    color: #555555;

  }

  .credit-card--date_exp,
  .credit-card--cdc {
    display: inline-block;

  }

  .credit-card--card {
    background-image: url(../../img/mini-carte.png);
    background-repeat: no-repeat;
    background-position-x: 2px;
    background-position-y: center;
    padding-left: 25px;
  }
}


.dotted {
  margin-top: 30px;
  width: 100%;
  border-bottom: 2px dotted #000;
  padding-bottom: 8px;
  margin-bottom: 15px;
  font-size: 1.4rem;
  font-weight: bold;
  list-style-image: none;
  position: relative;
}

#bloc_header_login {
  display: flex;
  justify-content: center;
}

.menu-compte {
  text-align: left;
}

.menu-compte--nom {
  display: block;
  font-size: 1.2rem;
  font-weight: bold;
  text-decoration: none;
  padding-left: 30px;
  padding-top: 8px;
  background: url(../../img/perso.png) no-repeat left top;
}

.menu-compte--films {
  font-weight: bold;
  font-size: 1.2rem;
  margin-left: 30px;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

#lp-offres,
.stripeForm,
.confirmation {
  h2 {
    font-family: $font-bebasneue !important;
    font-size: 2.8rem;
    text-transform: uppercase;
  }
}

.noautorisation {
  padding-top: 30px;

  h2 {
    font-family: $font-bebasneue !important;
    font-size: 2.8rem;
    text-transform: uppercase;
    padding-bottom: 20px;
  }

  p {
    padding-bottom: 20px;
  }
}

#lp-offres {
  p {
    //text-transform: uppercase;
    //font-family: $font-bebasneue;
    /*font-size: 21px;*/
    padding-bottom: 30px;
  }

  h3 {
    font-family: $font-bebasneue !important;
    font-size: 2.8rem;
    text-transform: uppercase;
  }
}

#sabonner-compte {
  h2 {
    font-family: $font-bebasneue !important;
    font-size: 2.8rem;
    text-transform: uppercase;
  }

  h3 {
    font-family: $font-raleway;
    font-size: 2rem;
    font-weight: bold;
    border-bottom: black 2px;
    border-bottom-style: dashed;
    border-bottom-width: thin;
  }

  #sabonner-compte-pas-compte {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}


.mot_de_passe_oublie a {
  text-decoration: underline;
  font-weight: bold;
}

.recapBigTitre,
.titre_promo {
  font-family: $font-raleway;
  font-weight: bold;
  font-size: 1.4rem;
  padding-top: 10px;
  padding-bottom: 10px;
}

.recap,
.recap h3 {
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 20px !important;
  font-weight: bold !important;
  text-transform: lowercase;
}

.recap h3 {
  text-transform: uppercase;
}

.offrir {

  font-size: px2rem(18);
  font-family: $font-raleway;
  font-weight: bold;
  border: 0px solid black;
  padding-right: 55px;
  padding-left: 55px;
  padding-top: 15px;
  padding-bottom: 15px;
  text-transform: uppercase;
  color: white;
  background: linear-gradient(to right, #f38274, #f8aa99);
  align-self: center;
  text-align: center;


  @media (max-width: 767px) {
    padding-left: 0px !important;
    padding-right: 0px !important;
    width: 100%;

  }


}

.bandeau_revue {
  display: flex;
  flex-direction: row;
  padding: 15px !important;
  flex-wrap: wrap;
}

.text_abo {
  font-family: LustSlimDidone, Times, Georgia, serif;
  font-size: 5.4rem;
  background-color: #fff;
  padding: 15px;
  font-feature-settings: "kern" on, "liga" on, "rlig" on, "calt" on, "ss01" on;

  @media (max-width: 767px) {
    background-color: unset;
    padding-left: 0px;
  }
}

.liens_confirmation {
  padding-top: 40px;
  padding-bottom: 40px;
}

.liens_confirmation a {
  padding-right: 30px;
}

.bandeau_promo_home {
  position: relative;
  z-index: 2000;
  margin-bottom: -40px;
}

div#tarteaucitronRoot {
  * {
    font-family: "Raleway", Arial, Verdana, sans-serif !important;
  }

  #tarteaucitronAlertBig {
    padding: 20px;
    width: 100%;
    @media (max-width: 576px) {
      width: auto;
    }
    @media (max-width: 576px) {
      #tarteaucitronPersonalize {
        display: block;
        margin: 15px auto;

      }
    }
  }

  #tarteaucitronDisclaimerAlert {
    font-size: 13px;
    font-family: "Raleway", Arial, Verdana, sans-serif;
  }

  #tarteaucitronPrivacyUrl,
  #tarteaucitronCloseAlert {
    background: none;
    color: #ffffff;
    text-decoration: underline;
    font-size: 12px !important;
    display: block;
    margin: 0 auto;
    padding: 0;
  }


  #tarteaucitronPersonalize {
    background: #000;
    font-family: "Raleway", Arial, Verdana, sans-serif;
    padding: 10px 20px;
  }

}

//OFFRIR UN ABONNEMENT

.bandeau_offrir_abo {
  background: url("../../img/bandeau_abo.png") !important;
  padding-bottom: 21px !important;
  padding-top: 21px !important;
  max-width: none;

  h1 {
    margin-bottom: 0 !important;
    color: #ffffff;
    line-height: 1em;
    text-align: left;
    font-size: 4.2rem;
    font-family: LustSlimDidone, Times, Georgia, serif;
    font-feature-settings: "kern" on, "liga" on, "rlig" on, "calt" on, "ss01" on;

    @media (max-width: 576px) {
      text-align: center;
    }
  }

  h2 {
    padding: 0px !important;
  }
}


.offrir_abo {

  h2 {
    font-size: 40px !important;
    font-family: $font-lustslim !important;
  }

  p#lp-offre-subtitle {
    font-family: $font-bebasneue !important;
    font-size: 19px;
  }

  h3.lp-offre-bloc-titre {

    font-size: 24px !important;
    font-family: $font-lustslim;

  }

  .lp-offre {

    .lp-offre-top {

      .lp-offre-periode {
        font-family: $font-bebasneue !important;
        font-size: 40px !important;
      }

      .lp-offre-precision {
        font-size: 14px !important;
      }
    }

  }
}

.asterisque_offrir_abo,
.asteriques {
  p {
    font-size: 11px !important;
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
    font-family: inherit !important;
    text-transform: inherit !important;
  }
}

.offrir_abo {
  #lp-offre-1 {
    .lp-offre-top {
      background: #dc4b4b !important;
      background-color: #dc4b4b !important;
      background-image: url("../../img/landingpage/motif-4.png") !important;
      background-image: url("../../img/landingpage/motif-4.png"), linear-gradient(to right, #dc4b4b 10%, #ff8e8e) !important;
    }
  }

  #lp-offre-2 {
    .lp-offre-top {
      background: #71AD97 !important;
      background-color: #71AD97 !important;
      background-image: url("../../img/landingpage/motif-4.png") !important;
      background-image: url("../../img/landingpage/motif-4.png"), linear-gradient(to right, #71AD97 10%, #AACBC7) !important;
    }
  }

  #lp-offre-3 {
    .lp-offre-top {
      background: #005576 !important;
      background-color: #005576 !important;
      background-image: url("../../img/landingpage/motif-4.png") !important;
      background-image: url("../../img/landingpage/motif-4.png"), linear-gradient(to right, #005576, #bbbfab) !important;
    }
  }
}


//Bandeau offrir un abo

.bloc_offrir_abo {
  .bandeau_revue {
    padding-left: 50px !important;
    padding-right: 45px !important;

    @media (max-width: 767px) {
      padding: 15px !important;
    }
  }

  .text_abo {
    flex-grow: 2;
  }
}

input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  box-sizing: border-box;
  padding: 0;
  width: 20px;
  height: 20px;
  background: #fff;
  border: 1px solid #ccc;
  vertical-align: middle;
  box-shadow: 0 3px 5px #555;
}

.asterisques p {
  text-transform: initial !important;
  font-family: $font-raleway !important;
  font-size: 11px !important;
}


#tarteaucitronAlertBig {
  .bloc_Pie_center {
    width: max-content;
    margin: 0 auto;
    text-align: left;

    #tarteaucitronCloseAlert, #tarteaucitronPrivacyUrl {
      margin: 0;
    }
  }

}

#bref_login_form {
  margin-top: 30px;
}

//Footer en bas de page
html, body {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
}

footer {
  margin-top: auto;
}

* {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

//404
#page404 {
  height: 100%;
}


select {
  appearance: none;
  border-radius: 0;
  height: 40px;
  color: #a19f9f;
  background: white;
  border: 1px solid #444;
  padding-left: 10px;
  padding-right: 10px;
  background: #fff url(../../img/select.svg) no-repeat 97% center;
  background-size: 20px 20px;
  accent-color: black;
}

:root {
  accent-color: black
}

select:hover option:hover {
  background: black !important;
  color: white !important;
}

select {
  accent-color: #0a246a;
}


/*=============
Aesthetics
=========================*/