$font-raleway: 'Raleway', Arial, Verdana, sans-serif;
$font-bebasneue: 'BebasNeue', Arial, Verdana, sans-serif;
$font-lustslim: 'LustSlimDidone', Times, Georgia, serif;


@mixin container-short() {
  max-width: 940px;
  margin: auto;
  padding-left: 10px;
  padding-right: 10px;
}

@mixin lien-filets($black:true) {
  font-family: $font-raleway;
  font-size: 1.2rem;
  text-transform: uppercase;
  font-weight: bold;
  text-decoration: none;
  display: inline-block;
  padding-left: 43px;
  transition: opacity .3s;
  border: none;
  padding-top: 6px;
  padding-bottom: 6px;
  @if ($black) {
    color: #000 !important;
    background: url(../../img/lien-filets--black.png) no-repeat;
  } @else {
    color: #ffffff !important;
    background: url(../../img/lien-filets--white.png) no-repeat;
  }

}

@function px2rem($size) {
  $remSize: $size / 10;
  //Default font size on html element is 100%, equivalent to 16px;
  @return #{$remSize}rem;
}


$menu-dark-blue : #005576;
$menu-dark-blue-end: #bbbfab;

$menu-red : #d42323;
$menu-red-end: #ff5d5d;

$menu-green : #4fad9a;
$menu-green-end : #c0efe6;

$menu-pink : #f38273;
$menu-pink-end: #f8aa9a;
