@font-face {
    font-family: 'Raleway';
    src: url('../../fonts/raleway/Raleway-Regular.eot');
    src: url('../../fonts/raleway/Raleway-Regular.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/raleway/Raleway-Regular.woff2') format('woff2'),
        url('../../fonts/raleway/Raleway-Regular.woff') format('woff'),
        url('../../fonts/raleway/Raleway-Regular.ttf') format('truetype'),
        url('../../fonts/raleway/Raleway-Regular.svg#Raleway-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Raleway';
    src: url('../../fonts/raleway/Raleway-Medium.eot');
    src: url('../../fonts/raleway/Raleway-Medium.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/raleway/Raleway-Medium.woff2') format('woff2'),
        url('../../fonts/raleway/Raleway-Medium.woff') format('woff'),
        url('../../fonts/raleway/Raleway-Medium.ttf') format('truetype'),
        url('../../fonts/raleway/Raleway-Medium.svg#Raleway-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Raleway';
    src: url('../../fonts/raleway/Raleway-Bold.eot');
    src: url('../../fonts/raleway/Raleway-Bold.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/raleway/Raleway-Bold.woff2') format('woff2'),
        url('../../fonts/raleway/Raleway-Bold.woff') format('woff'),
        url('../../fonts/raleway/Raleway-Bold.ttf') format('truetype'),
        url('../../fonts/raleway/Raleway-Bold.svg#Raleway-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'BebasNeue';
    src: url('../../fonts/bebas/BebasNeueRegular.eot');
    src: url('../../fonts/bebas/BebasNeueRegular.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/bebas/BebasNeueRegular.woff2') format('woff2'),
        url('../../fonts/bebas/BebasNeueRegular.woff') format('woff'),
        url('../../fonts/bebas/BebasNeueRegular.ttf') format('truetype'),
        url('../../fonts/bebas/BebasNeueRegular.svg#BebasNeueRegular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'BebasNeue';
    src: url('../../fonts/bebas/BebasNeueBook.eot');
    src: url('../../fonts/bebas/BebasNeueBook.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/bebas/BebasNeueBook.woff2') format('woff2'),
        url('../../fonts/bebas/BebasNeueBook.woff') format('woff'),
        url('../../fonts/bebas/BebasNeueBook.ttf') format('truetype'),
        url('../../fonts/bebas/BebasNeueBook.svg#BebasNeueBook') format('svg');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'BebasNeue';
    src: url('../../fonts/bebas/BebasNeueBold.eot');
    src: url('../../fonts/bebas/BebasNeueBold.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/bebas/BebasNeueBold.woff2') format('woff2'),
        url('../../fonts/bebas/BebasNeueBold.woff') format('woff'),
        url('../../fonts/bebas/BebasNeueBold.ttf') format('truetype'),
        url('../../fonts/bebas/BebasNeueBold.svg#BebasNeueBold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'LustSlimDidone';
    src: url('../../fonts/lustslim/LustSlim-Didone.eot');
    src: url('../../fonts/lustslim/LustSlim-Didone.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/lustslim/LustSlim-Didone.woff2') format('woff2'),
        url('../../fonts/lustslim/LustSlim-Didone.woff') format('woff'),
        url('../../fonts/lustslim/LustSlim-Didone.ttf') format('truetype'),
        url('../../fonts/lustslim/LustSlim-Didone.svg#LustSlim-Didone') format('svg');
    font-weight: normal;
    font-style: normal;
}
