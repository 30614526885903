
#contact_rub {
  @include container-short();
  padding-top: 50px;
}


/*form{
	.simpleform {
		max-width: 460px;
	}

	.simpleform label {
		display: block;
		font-family: $font-raleway;
		font-size: px2rem(13);
		font-weight: bold;
	}

	.simpleform input.text,
	.simpleform input.password,
	.simpleform textarea,
	input[type="file"] {
		background: #e6e6e6;
		border: none;
		font-size: px2rem(13);
		font-family: $font-raleway;
		padding: 5px;
		width: 100%;
		max-width: 460px;
	}

	.simpleform_field {
		margin-bottom: 20px;
	}

	.simpleform_field.div_submit {
		text-align: right;
	}

	.simpleform input.submit {
		@include lien-filets();
	}
}*/


/******************************************************************/
//Apparence des formulaires.
/******************************************************************/
.formError {
  border: 2px dotted #cc0000 !important;
}

form {
  .simpleform_field {
    margin-bottom: 15px;
    // max-width: 460px;

    &.div_hidden {
      margin: 0;
    }
  }

  .need {
    font-size: 1em;
    margin-bottom: 15px;
  }

  fieldset {
    border-bottom: #000000 solid 4px;
    padding-top: 25px;
    position: relative;

    legend {
      font-family: Raleway,Arial,Verdana,sans-serif;
      display: block;
  
  // font-size: 1em;
  // font-weight: 700;
      border-bottom: #000000 solid 4px;
      padding-bottom: 15px;
      // margin-bottom:25px;
      color: #000000;
      font-size: 1.4em;
      font-weight: bold;
      margin: 0;
    }
  }

  label {
    font-family: $font-raleway;
    display: block;
    color: #000000;
    font-size: 1em;
    font-weight: bold;

    span.aide {
      font-size: 0.7em;
    }
  }

  &.simpleform input.date,
  &.simpleform input.text,
  &.simpleform input.password,
  &.simpleform textarea,
  &.simpleform input[type="file"],
  &.simpleform div.text.form-control {
    border: #e6e6e6 solid 1px;
    background: #e6e6e6;
    color: #000000;
    font-size: 1em;
    font-style: italic;
    font-family: $font-raleway;
    width: 100%;
    padding: 10px 5px;
    height: 40px;
  }

  textarea {
    height: auto !important;
  }

  input.date[type="date"] {
    background: #e6e6e6 url(../../img/calendar.svg) 99% 50% no-repeat;
  }

  [type="date"]::-webkit-inner-spin-button {
    display: none;
  }

  [type="date"]::-webkit-calendar-picker-indicator {
    opacity: 0;
  }


  .checkbox {
    label {
      margin-left: 10px;

      @include media-breakpoint-down(xs) {
        margin-left: 20px;
      }

      input {
        float: left;
        margin-top: 3px;
      }

      p {
        padding-left: 10px;
        margin-left: 30px;
        font-size: 1.3rem;
        vertical-align: middle;
        padding-top: 5px;
      }
    }
  }

  button {
    font-size: 1.8em;
    margin-top: 20px;
  }

  //pass_conf.
  .div_pass_conf div:nth-child(2) {
    margin-top: 20px;
  }

  //Gestion des select.
  .div_select span.select {
    display: block;
    position: relative;
    overflow: hidden;
    background-color: #fff;
    // margin: 0 0 1.5em 0;

    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      width: 110%;
      height: auto;
      border: 0;
      margin: 0;

      font-size: 1em;
      font-style: italic;
      font-family: $font-raleway;
      width: 100%;
      padding: 10px 5px;
      border: #e6e6e6 solid 1px;
      background: #e6e6e6;

      border-radius: 0;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &::after {
      content: '';
      display: block;
      pointer-events: none;
      position: absolute;
      width: 40px;
      height: 100%;
      right: 1px;
      top: 1px;
      z-index: 10;
      background-image: url(../../img/select.png);
      background-repeat: no-repeat;
      background-position: center;
      border-left: 1px solid #000000;
    }
  }

  .simpleform_field.div_submit {
    text-align: right;
  }

  &.simpleform input.submit {
    @include lien-filets();
  }

  .div_radio {
    label {
      display: inline-block;
    }
  }
}


details {

  summary {
    width: 100%;
    border-bottom: 2px dotted #000;
    padding-bottom: 8px;
    margin-bottom: 15px;
    font-size: px2rem(15);
    font-weight: bold;
    list-style-image: none;
    position: relative;

    list-style-type: none;

    &::-webkit-details-marker, &::marker {
      display: none;
    }

    &::after {
      content: '';
      width: 24px;
      height: 24px;
      position: absolute;
      top: 0;
      right: 0;
      background: url(../../img/arrow-down.png) no-repeat center center;
      transition: transform .3s;
    }


  }

  &[open] {
    summary {
      &::after {
        transform: rotate(180deg);
      }
    }
  }

  padding-bottom: 30px;
}

.error-message {
  color: #ff0000;
  padding-left: 20px;
  background: url(../../img/error.png) no-repeat;
}

.valid-message {
  padding-left: 20px;
  background: url(../../img/valid.png) no-repeat;
}


.form-butons {
  vertical-align: middle;
  display: flex;
  align-items: baseline;
  justify-content: flex-end;
  margin-bottom: 30px;

  > * {
    margin-left: 20px;
  }

  .btn-gradient {
    display: inline-block;
  }
}

.compte-past .btn-gradient {
  display: inline-block;
}

button.btn.btn-gradient[disabled] {
  color: rgba(0, 0, 0, 0);
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url(../../img/owlcarousel/ajax-loader.gif);
}


.center {
  position: absolute;
  display: inline-block;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

// .custom-select-wrapper {
//   position: relative;
//   display: inline-block;
//   user-select: none;
//   cursor: pointer;
//   width: 100%;
// }
// .custom-select-wrapper select {
//   display: none;
// }
// .select-list {
//   width: 100%;
//   position: relative;
//   display: inline-block;
//   font: 13px/1.5 "Roboto", sans-serif;
//   border: 1px solid #e6e6e6;
// }
// .custom-select-trigger {
//   min-height: 40px;
//   position: relative;
//   display: block;
//   min-width: 130px;
//   padding: 10px 5px;
//   font-weight: 100;
//   color: #6e7c83;
//   background-color: #ffffff;
//   cursor: pointer;
//   transition: all 0.2s ease-in-out;
// }
// .custom-select-trigger:hover {
//   -webkit-box-shadow: 0px 10px 50px 0px rgba(43, 111, 246, 0.1);
//   box-shadow: 0px 10px 50px 0px rgba(43, 111, 246, 0.1);
// }
// .custom-select-trigger:before {
//   position: absolute;
//   display: block;
//   content: "";
//   width: 1px;
//   height: 100%;
//   top: 3px;
//   right: 50px;
//   margin-top: -3px;
//   border-right: 1px solid #c7d1d6;
//   transition: all 0.35s ease-out;
//   transform-origin: 50% 0;
// }
// .custom-select-trigger:after {
//   position: absolute;
//   display: block;
//   content: "";
//   width: 10px;
//   height: 10px;
//   top: 50%;
//   right: 20px;
//   margin-top: -3px;
//   border-bottom: 1px solid #c7d1d6;
//   border-right: 1px solid #c7d1d6;
//   transform: rotate(45deg) translateY(-50%);
//   transition: all 0.35s ease-out;
//   transform-origin: 50% 0;
// }
// .select-list.opened .custom-select-trigger:after {
//   margin-top: 3px;
//   transform: rotate(-135deg) translateY(-50%);
// }
// .custom-options {
//   z-index: 10;
//   position: absolute;
//   display: block;
//   top: 100%;
//   left: 0%;
//   right: 0;
//   width: 100%;
//   margin: 10px 0;
//   border-radius: 5px;
//   box-sizing: border-box;
//   background: #fff;
//   transition: all 0.2s ease-in-out;
//   opacity: 0;
//   visibility: hidden;
//   pointer-events: none;
//   transform: translateY(-15px);
//   border: 0;
//   -webkit-transition: all 0.2s ease-in-out;
//   -moz-transition: all 0.2s ease-in-out;
//   -ms-transition: all 0.2s ease-in-out;
//   -o-transition: all 0.2s ease-in-out;
//     overflow: scroll;
// }
// .select-list.opened .custom-options {
//   border: 1px solid #e6e6e6;
//   opacity: 1;
//   visibility: visible;
//   pointer-events: all;
//   transform: translateY(-8px);

//   -webkit-box-shadow: 0px 10px 50px 0px rgba(43, 111, 246, 0.1);
//   box-shadow: 0px 10px 50px 0px rgba(43, 111, 246, 0.1);
//   max-height: 300px;
//     overflow: auto;
// }
// .option-hover:before {
//   background: #f9f9f9;
// }
// .custom-option {
//   position: relative;
//   display: block;
//   padding: 0 22px;
//   font: 13px/1.5 "Roboto", sans-serif;
//   font-weight: 600;
//   color: #005576;
//   line-height: 47px;
//   cursor: pointer;
//   transition: all 0.05s ease-in-out;
// }
// .custom-option:first-of-type {
//   border-radius: 4px 4px 0 0;

//   border-top-right-radius: 5px;
//   border-top-left-radius: 5px;
// }
// .custom-option:last-of-type {
//   border-bottom: 0;
//   border-radius: 0 0 4px 4px;
//   border-bottom-right-radius: 5px;
//   border-bottom-left-radius: 5px;
// }
// .custom-option:hover,
// .custom-option.selection {
//   color: #fff;
//   background-color: #005576;
// }


.titre_partie_compte legend {
  display: none;
}

//RGPD addon
.pwdValidator{
  &-encart{
    border-radius: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    padding-top: 15px;
    padding-left: 15px;
    padding-right:15px ;
    padding-bottom:5px ;
    list-style: none;
    ul{
      list-style: none;
    }
  }
  &-error{
    background-color: #ff00009f;
  }
  &-correct{
    background-color: #00ff009f;
  }
}

#label_c_consent_RGPD p{
  display: none;
}

#c_consent{
  legend{
    width:90%;
    position: relative;
    right:-10%;
  }
  label{
    width: 10%;
    position: relative;
    left: 0;
    top:-50px;
    padding-left: 20px;
  }
}