.abo-top h2 {
  text-align: center;
  padding: 30px;
  text-transform: uppercase;
  font-family: "BebasNeue", Arial, Verdana, sans-serif;
  font-size: 2.8rem;
}

.progression {
  max-width: 100%;
  overflow-x: auto;
  @media (max-width: 576px) {
    display: none;
  }
  ul {

    display: flex;
    list-style-type: none;
    padding: 0;
    margin: 0;
    flex-grow: 1;
    margin-bottom: 40px;

    li {
      padding: 0;
      margin: 0;
      flex-grow: 1;
      text-align: center;
      position: relative;
      padding: 20px;
      font-weight: bold;
      font-size: 1.4rem;
      opacity: .5;

      &.active {
        opacity: 1;
      }

      &::before {
        content: '';
        left: 0;
        position: absolute;
        bottom: 4px;
        width: 100%;
        border-top: 2px solid;
      }

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: calc(50% - 5px);
        width: 10px;
        height: 10px;
        border-radius: 100%;
        background: #000000;
        overflow: hidden;
      }
    }
  }


}

.abo_gratuit_msg{
  text-align: center;

  .abo_gratuit_msg_titre{
    font-size:1.6rem;
    font-weight: bolder;
    margin-bottom:15px;
  }
  .abo_gratuit_msg_texte{
    font-weight:bold;
    margin-bottom:40px;
  }
}

#fieldset_compte_jesuis, #compte_newsletters fieldset {
  border: 0;

  margin: 0;
  padding: 0;

  legend {
    border: 0;
    font-size: 1em;
    margin: 0;
  }
}

#fieldset_compte_jesuis .div_radio {
  display: inline-block;
  padding-right: 20px;


  /* Custom radio buttons */
  input[type="radio"] + label {
    display: inline-block;
    cursor: pointer;
    position: relative;
    padding-left: 30px;
    margin-right: 15px;
    font-size: 13px;

    &:before {
      content: "";
      display: block;
      width: 22px;
      height: 22px;
      margin-right: 14px;
      position: absolute;
      top: -3px;
      left: 0;
      border: 1px solid #aaa;
      background-color: #fff;
      border-radius: 50%;
      box-shadow: 5px 5px 5px #eee;
    }


  }

  input[type="radio"] {
    display: none !important;
    *display: inline;

    &:checked + label:after {
      content: "";
      font-family: "Times New Roman";
      display: block;
      position: absolute;
      top: 3px;
      left: 6px;
      line-height: 1px;
      font-size: 28px;
      color: #555555;
      width: 10px;
      height: 10px;
      background: #555;
      -webkit-border-radius: 100%;
      -moz-border-radius: 100%;
      border-radius: 100%;
      font-weight: normal;

    }
  }
}


#compte_newsletters {

}

.promo {
  text-align: center;
  margin-bottom: 20px;
}

.promo h3 {
  text-transform: uppercase;
  font-size: 1.8rem;
}

.promo input {
  width: 50%;
  margin-right: 20px;
}

.promo button {
  background: url(../../img/lien-filets--white.png) no-repeat transparent;
  color: #ffffff;
  text-transform: uppercase;
  border: none;
  font-size: 1.4rem;
  font-family: Raleway, Arial, Verdana, sans-serif;
  font-weight: bold;
  padding-left: 40px;
}

.recap {
  color: #ffffff;
  padding: 30px;
  text-align: center;
  font-weight: bold;
  background-image: url(../../img/landingpage/motif-3.png), linear-gradient(90deg, #71ad97 10%, #aacbc7);
  margin-bottom: 20px;
}

.bandeau_abo{
   background: url("../../img/bandeau_abo.png");
   padding-bottom: 15px;
   padding-top: 15px;
   max-width: none;
 }

.link_bandeau_abo, .link_bandeau_abo_groupes{
  text-decoration: none;
  font-size: px2rem(42);
  font-family: $font-lustslim;
  color: #fcd5cd;
  font-feature-settings: "kern" on,"liga" on,"rlig" on,"calt" on,"ss01" on;
}

.abo_active{
  color: white !important;
}

.bloc_abo_groupe{
  position: relative;
  height: calc(100% - 28px);
  display: flex;
  flex-direction: column;
  background-color: #f0f0f0;
  margin-top: 28px;

  .img_bloc_abo_groupe{
    width: 63px;
    height: 26px;
    margin-left: 50%;
    transform: translateX(-50%);
    margin-top: 30px;
    margin-bottom: 10px;
  }

  .img_bloc_abo_institutionnelle{
    width: 64px;
    height: 35px;
    margin-left: 50%;
    transform: translateX(-50%);
    margin-top: 30px;
    margin-bottom: 10px;
  }

  .titre_bloc_abo_groupe{
    font-size: px2rem(28);
    font-family: $font-bebasneue;
    color: black;
    text-transform: uppercase;
    text-align: center;
  }

  .contenu_bloc_abo_groupe{
    padding: 20px 20px 20px 20px;
    padding-top: 35px;
    display: flex;
    flex-direction: column;
    width: 100%;
    font-family: $font-raleway;
    font-size: px2rem(14);
    white-space: pre-line;

    .button_bref_numerique{
      width: 100%;
      background-image: url("../../img/bouton_abo_groupe.png");
      background: linear-gradient(to left, #c4dbd8, #71ad98);
      color: white;
      text-transform: uppercase;
      text-align: center;
      padding-left: 72px;
      padding-right: 72px;
      font-family: $font-raleway;
      font-size: px2rem(16);
      margin-top: 10px;
      text-decoration: none;
      padding-bottom: 10px;
      padding-top: 10px;
      margin-bottom: 2px;
    }

    .button_bref_integral{
      width: 100%;
      background-image: url("../../img/bouton_abo_groupe.png");
      background: linear-gradient(to left, #d0d2c4, #015676);
      color: white;
      text-transform: uppercase;
      text-align: center;
      padding-left: 72px;
      padding-right: 72px;
      font-family: $font-raleway;
      font-size: px2rem(16);
      margin-top: 10px;
      text-decoration: none;
      padding-bottom: 10px;
      padding-top: 10px;
      margin-bottom: 2px;
    }

    .last_button{
      margin-bottom: 82px;
    }

    .texte_abo_groupe2{
      margin-top: 35px;
    }

    .button_demande_devis{
      width: 92.5%;
      background-image: url(../../img/bouton_abo_groupe.png);
      color: #000;
      text-transform: uppercase;
      text-align: center;
      padding: 13px 32px;
      font-family: Raleway,Arial,Verdana,sans-serif;
      font-size: 1.6rem;
      margin-top: 10px;
      text-decoration: none;
      position: absolute;
      bottom: 23px;


      .texte_bouton_demande_devis{
        background-color: white;
        padding-top: 14px;
        padding-bottom: 14px;
        font-weight: bold;
        transition: ease 0.3s;
        -webkit-transition: ease 0.3s;
        -moz-transition: ease 0.3s;
        -ms-transition: ease 0.3s;
        -o-transition: ease 0.3s;
        &:hover {
          background-color: #000000;
          color: #fff;
        }
      }
    }
  }
}

.bandeau_offir_abo h2{
  padding: 0px !important;
}


.contenu_bloc_abo_groupe a strong {
  font-size: 20px;
}

.contenu_bloc_abo_groupe a:hover{
  color: white;
  text-decoration: none;
}

#lp-offres h3~p {
  font-family: $font-bebasneue;
  font-size: px2rem(20);
}

