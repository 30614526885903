#film_formulaire {
  background: url(../../img/trame.png);
  padding: 20px 0 10px 0;
  margin-bottom: 45px;

  form {
    &.simpleform input.text,
    &.simpleform input.password,
    &.simpleform textarea,
    &.simpleform input[type="file"] {
      border: #ffffff solid 1px;
      background: #ffffff;
    }

    //Gestion des select.
    .div_select span.select {
      select {
        border: #ffffff solid 1px;
        background: #ffffff;
      }
    }

    .simpleform_field.div_submit {
      text-align: left;

      @include media-breakpoint-down(sm) {
        text-align: right;
      }
    }
  }
}

#film_filtres {
  form {
    label {
      float: left;
      padding: 10px 10px 0 0;
    }
  }
}

#film_resultats_wrapper {
  padding-bottom: 25px;
}

#film_resultats_nb {
  font-family: $font-bebasneue;
  font-size: 2.6em;
}

#film_resultats_wrapper {
  padding-bottom: 25px;
}

#film_resultats_nb {
  font-family: $font-bebasneue;
  font-size: 2.6em;
}


@include media-breakpoint-down(xs) {

}

.titre_page_films {
  font-family: $font-lustslim;
  font-size: px2rem(54) !important;
  font-feature-settings: "kern" on, "liga" on, "rlig" on, "calt" on, "ss01" on;

  &:first-letter {
    font-size: px2rem(80) !important;
  }
}

/****************************************************************/
/*bloc film vignette*/
/****************************************************************/

.bloc_film_vignette {
  margin: 10px 0;

  a {
    display: block;
    position: relative;
    color: #ffffff;
    padding-top: 56.25%;


    .film_image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      img {
        // max-width:100%;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .film_infos_top {
      display: block;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      padding: 12px 15px;
      z-index: 5;

      .film_expiration {
        .expiration_picto {
          background: #000000;
          width: 32px;
          height: 32px;
          line-height: 32px;
          text-align: center;
          display: block;
          border-radius: 50%;
          font-size: 0.9em;
        }
      }

      .film_date_duree {
        font-size: 1.2em;
        opacity: 0;
        transition: all .3s;
        transform: translateY(-10px);
      }
    }

    .film_infos_bottom {
      display: block;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 12px 15px;
      z-index: 5;
      background: linear-gradient(to bottom, transparent 0%, #000 100%);

      h3 {
        font-size: 1.6em;
        line-height: 1.1em;
        font-family: $font-lustslim;
        margin: 0;
      }

      h4.film_realisateur {
        margin: 0;
        font-size: 1.0em;
        font-weight: bold;
        padding-top: 6px;
        padding-bottom: 6px;
      }


    }

    .film_infos_bottom .film_pictos .picto_favoris {
      display: inline-block;
    }

    &:hover {

      .film_infos_top .film_date_duree {
        opacity: 1;
        transform: translateY(0px);
      }


    }
  }
}

.bloc_film, .bloc_film_vignette a {

  .film_pictos {

    .picto_favoris, .player {
      visibility: hidden;
      opacity: 0;
      transition: all .3s;
      transform: translateY(10px);

      button {
        display: block;
        background: url(../../img/picto_favoris.png) no-repeat center center;
        width: 25px;
        height: 25px;
        border: none;
        cursor: pointer;
        outline: none;
        margin-top: 6px;

        &.selected {
          background-image: url(../../img/picto_favoris_selected.png);
        }

        &:hover {
          opacity: 0.7;
        }
      }
    }

    .picto_bonus {
      display: inline-block;


      img {
        width: 29px;
        height: 29px;
        margin-left: 4px;
        margin-top: 4px;
      }
    }
  }

  .player {
    visibility: hidden;
    opacity: 0;
    transition: all .3s;
    transform: translateX(-10px);
  }

  &:hover {
    .picto_favoris, .player {
      visibility: visible !important;
      opacity: 1 !important;
      transform: translateY(0px) rotate(0deg) scale(1) !important;
    }
  }
}

.bloc_film_620x349 {
  h3 {
    font-size: 2em !important;
  }

  h4.film_realisateur {
    font-size: 1.3em !important;
  }
}

/****************************************************************/
/*Fiche détaillée*/
/****************************************************************/

#film_fiche {
  margin-bottom: 35px;

  a {
    color: #fa8573;
    text-decoration: none;
  }

  #fiche_btn_retour {
    margin: 110px 0 15px 0;
  }

  #fiche_player {
    padding-bottom: 30px;
    position: relative;

    #video_media_wrapper {
      background: #000000;
      position: relative;
      margin: 0 10px;
      width: 100%;
      height: 100%;
    }

    #video_player {
      position: relative;

      #video_btn_close {
        position: absolute;
        left: 15px;
        top: 15px;
        background-color: rgba(0, 0, 0, 0.5);
        border: 1px solid #ffffff;
        color: #ffffff;
        font-size: 0.9em;
        font-weight: bold;
        // line-height: 0.9em;
        padding: 14px 20px;
        text-transform: uppercase;
        cursor: pointer;

        &:hover {
          background-color: #ffffff;
          color: #000000;
          text-decoration: none;
        }
      }

    }

    #video_cover {
      // background:#000000;
      position: relative;

      img {
        width: 100%;
        vertical-align: bottom;
      }

      #video_btn_play {
        position: absolute;
        left: 50%;
        top: 50%;
        margin-left: -11px;
        margin-top: -15px;
        width: 21px;
        height: 29px;
        background: url(../../img/play.png) left top no-repeat;
      }

      #video_btn_extrait {
        position: absolute;
        right: 15px;
        bottom: 15px;
        background-color: rgba(0, 0, 0, 0.5);
        border: 1px solid #ffffff;
        color: #ffffff;
        font-size: 0.9em;
        font-weight: bold;
        // line-height: 0.9em;
        padding: 14px 28px;
        text-transform: uppercase;
        cursor: pointer;

        &:hover {
          background-color: #ffffff;
          color: #000000;
          text-decoration: none;
        }
      }
    }

    .popup-wrapper {
      position: absolute;
      display: block;
      width: 100%;
      left: 0px;
      top: 0px;
      padding-top: 40px;

      .popup-content {
        background: #ffffff;

        .popup-close {
          text-align: right;
          padding: 10px 0;

          .croix {
            margin: 0;
          }
        }

        .popup-infos {
          padding: 0 30px 15px 30px;

          .popup-description {
            margin-bottom: 25px;
          }

          .popup-label {
            font-weight: bold;
            border-bottom: #000000 dotted 2px;
            padding-bottom: 10px;
            margin-bottom: 25px;
          }

          .popup-btn {
            margin-bottom: 20px;
            padding-top: 7px;
          }
        }
      }
    }

  }

  #fiche_titre {

    h1 {
      // font-size: 42px;
      font-size: 3.2em;
      line-height: 1em;
      font-family: $font-lustslim;
      padding: 0;
      margin: 0;
      color: #005576;
      display: inline-block;
    }

    #fiche_pictos {

      .picto_expiration {
        display: inline-block;
        vertical-align: top;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: #000000;
        color: #ffffff;
        line-height: 40px;
        text-align: center;
        font-size: 15px;
        // font-family: $font-raleway;
        font-weight: bold;
        margin-left: 10px;
      }

      .picto_bonus {
        display: inline-block;
        vertical-align: top;
        width: 40px;
        height: 40px;
        line-height: 40px;
        margin-left: 5px;
      }
    }

    .fiche_directors {

      min-height: 25px;

      p {
        font-weight: bold;
        font-size: 1.5em;
        margin: 10px 0;
      }
    }
  }

  #fiche_tools {

    .picto_tool {
      display: inline-block;
      vertical-align: top;

      &:hover {
        opacity: 0.7;
      }
    }

    #picto_favoris {

      button {
        display: block;
        background: url(../../img/picto_favoris_noir.png) no-repeat center center;
        width: 30px;
        height: 30px;
        border: none;
        cursor: pointer;
        outline: none;
        vertical-align: bottom;

        &.selected {
          background-image: url(../../img/picto_favoris_selected_noir.png);
        }
      }
    }

    .picto_share {

      a {
        display: inline-block;
        height: 30px;
        width: 30px;
        text-align: center;
        line-height: 30px;

        img {
          height: 20px;
        }
      }

    }

    #picto_twitter {
      img {
        display: inline-block;
        margin-top: 2px;
        height: 18px;
      }
    }

  }

  #fiche_infos {
    margin-top: 20px;

    .strong {
      font-weight: bold;
    }

    p {
      font-size: 1.2em;
      margin-bottom: 5px;
    }

    #fiche_date_duree, #fiche_genre {
      p {
        font-weight: bold;
      }
    }

    #fiche_interpretation li {
      font-size: 1.2em;
    }

  }

  .fiche_para {
    border-top: dotted 2px #000000;
    padding-top: 22px;
    margin-top: 25px;

    h2 {
      // color: $color_green;
      color: #005576;
      text-transform: uppercase;
      font-size: 2em;
      font-family: $font-bebasneue;
      margin: 0;
      padding: 0;

      &.fiche_para_collapse {
        cursor: pointer;

        > span {
          background: transparent url(../../img/arrow-up.png) center center no-repeat;
          width: 24px;
          height: 24px;
          display: block;
          float: right;
          margin-top: 3px;
        }

        &.collapsed {
          > span {
            background: transparent url(../../img/arrow-down.png) center center no-repeat;
          }
        }
      }

      &:before {
        content: '// ';
      }
    }

    .fiche_text {
      padding-left: 60px;
      padding-top: 22px;

      h3 {
        font-size: 14px;
        font-weight: bold;
        padding-bottom: 10px;
        padding-top: 15px;
      }

      p {
        padding-bottom: 8px;
        font-size: 14px;
        line-height: 20px;
      }

      @include media-breakpoint-down(sm) {
        padding-left: 0;
      }
    }

  }

  #fiche_tools {
    padding-top: 3px;
    text-align: right;

    p {
      font-size: 16px;
      font-weight: bold;

      img {
        vertical-align: baseline;
      }
    }
  }

  #fiche_a_retrouver {
    background: transparent url(../../img/trame.png) left top repeat;
    padding: 20px 20px 10px 20px;
    margin-bottom: 10px;

    h2 {
      color: #005576;
      text-transform: uppercase;
      font-size: 2em;
      font-family: $font-bebasneue;
      margin-bottom: 15px;
    }

    .retrouver_liste {
      padding-bottom: 10px;

      h3 {
        text-transform: uppercase;
        font-size: 1.1em;
        font-weight: bold;
        margin: 5px 0 0 0;
      }

      ul {
        margin: 0;
        padding: 0;
        list-style-type: none;

        li {
          font-size: 14px;
          font-weight: 500;

          a {
            color: #005576;
            font-weight: bold;
          }
        }
      }
    }
  }

  #fiche_biographies {

    .nav-pills {
      margin-bottom: 5px;

      > li {
        display: inline-block;
        border-right: 1px solid #000000;
        padding: 0 12px;
        font-size: 14px;

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          border: 0;
        }

        &.active {
          > a, > a:hover, > a:focus {
            font-weight: bold;
            border-bottom: 4px solid #000000;
          }
        }

        > a {
          text-align: left;
          padding: 7px 2px;
          border-radius: 0;
          background-color: transparent;
          color: #000000;
          border-bottom: 4px solid #ffffff;
          text-decoration: none;
          display: block;

          &:hover, &:focus {
            opacity: 0.8;
          }

          &.active {
            font-weight: bold;
            border-bottom: 4px solid #000000;
          }
        }
      }
    }

    @include media-breakpoint-down(sm) {
      .tab-pane {
        display: block !important;
        opacity: 1 !important;
      }
    }

  }

}

/****************************************************************/
/*blocs en relation*/
/****************************************************************/

#fiche_en_relation {


  #fiche_voir_aussi {
    background: url(../../img/trame.png);
    padding: 20px 10px;
  }

  .relation_liste {
    padding: 0px 10px 0 10px;

    h2 {
      // color: $color_green;
      color: #005576;
      text-transform: uppercase;
      font-size: 2.8em;
      font-family: $font-bebasneue;

      &:before {
        content: '// ';
      }
    }
  }

  #fiche_bonus {
    background: url(../../img/trame.png);
    padding: 20px 10px;

    .bloc_film_bonus {
      margin-bottom: 20px;
    }

    .bonus_titre {
      font-size: 1.3em;
      font-weight: bold;
      margin-top: 10px;
    }
  }

}

.bloc_film_vignette .picto_bonus img {
  vertical-align: bottom;
}


#film_form select, #film_form_sort select {
  color: #a19f9f;
  height: 40px;
  width: 300px;

}

#film_form select {
  border: none;
}


#offrir {
  position: absolute;
  right: 10px;
  bottom: 0;
  background: #ffffff;
  box-shadow: rgba(0, 0, 0, .12) 5px 5px 10px;
  padding: 50px;
  padding-bottom: 30px;
  max-width: 100%;
  z-index: 9;
  opacity: 0;
  visibility: hidden;
  transform: translateY(20px);
  transition: all .5s;

  &.active {
    transform: none;
    opacity: 1;
    visibility: visible;
  }

  .cancel {
    position: absolute;
    right: 10px;
    top: 5px;
  }

  .offrir-inside > img {
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
  }

  .div_submit > input {
    background: none;
    padding: 15px;
    background: #000;
    color: #ffffff !important;
    text-align: center;
    width: 100%;
  }

  .offrir-inside {
    max-width: 300px;
  }

  .important.need {
    display: none;
  }
}


@media screen and (max-width: 991px) {
  #offrir {
    bottom: auto;
    top: 0;

  }
}
